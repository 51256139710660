import {
  SAVE_MESSAGE,
  SAVE_ACTIVE_SUPPORT_USER,
  SAVE_SUPPORT_USER_MESSAGES,
  SAVE_SUPPORT_USER_MORE_MESSAGES,
} from '../actions/activeUser'
import { BEFORE_MESSAGES } from '../components/MessagesWrap/config'

const initialState = {
  botActive: null,
  chatId: null,
  chatStatus: null,
  firstName: null,
  lastMessage: null,
  lastName: null,
  messageDatetime: null,
  openedAt: null,
  platform: null,
  postback: null,
  profilePic: null,
  supportRequest: null,
  unreadMessage: null,
  unreadMessages: null,
  userId: null,
  userMessageDatetime: null,
  messages: [],
}

const _handleSaveMoreMessages = (messages, payload) => {
  return payload.direction === BEFORE_MESSAGES
    ? [...messages, ...payload.messages]
    : [...payload.messages, ...messages]
}

const activeUser = (state = initialState, { type, payload }) => {
  switch (type) {
  case SAVE_ACTIVE_SUPPORT_USER:
    return {
      ...state,
      ...payload.activeUser,
      messages: state.messages,
    }

  case SAVE_SUPPORT_USER_MESSAGES:
    return {
      ...state,
      messages: payload.messages,
    }

  case SAVE_SUPPORT_USER_MORE_MESSAGES:
    return {
      ...state,
      messages: _handleSaveMoreMessages(state.messages, payload),
    }

  case SAVE_MESSAGE:
    return {
      ...state,
      messages: [payload.message, ...state.messages],
    }

  default:
    return state
  }
}

export default activeUser
