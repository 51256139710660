import { BASE_URL } from '../../../configs'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { SUPPORT_MESSAGES_SIZE } from '../../../constants/messagesSize'
import { AROUND_MESSAGES } from '../components/MessagesWrap/config'

export function getMessages(chatId) {
  return fetch(`${BASE_URL}/support/chat/${chatId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() =>
      alertError('Sorry but something going wrong please ping support!', 5000),
    )
}

export function getMoreMessages(messageId, chatId, direction) {
  return fetch(
    `${BASE_URL}/support/chat/${chatId}?messageId=${messageId}&size=${SUPPORT_MESSAGES_SIZE}&direction=${direction}`,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() =>
      alertError('Sorry but something going wrong please ping support!', 5000),
    )
}

export function closeUserRequest(json) {
  return fetch(`${BASE_URL}/support/backUserToBot`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  }).catch(() =>
    alertError('Sorry but something going wrong please ping support!', 5000),
  )
}

export function assignSupportRequest(botId, json) {
  return fetch(`${BASE_URL}/support/assign`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  }).catch(() =>
    alertError('Sorry but something going wrong please ping support!', 5000),
  )
}

export function getAdminsList(botId) {
  return fetch(`${BASE_URL}/support/admins/${botId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() =>
      alertError('Sorry but something going wrong please ping support!', 5000),
    )
}

export function setMessageRead(chatId, messageId) {
  return fetch(`${BASE_URL}/support/read/user/${chatId}/message/${messageId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
  }).catch(() =>
    alertError('Sorry but something going wrong please ping support!', 5000),
  )
}

export function getMessagesById(chatId, messageId) {
  const size = Math.round(SUPPORT_MESSAGES_SIZE / 2)

  return fetch(
    `${BASE_URL}/support/chat/${chatId}?messageId=${messageId}&size=${size}&direction=${AROUND_MESSAGES}`,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => {
      alertError('Sorry but something going wrong please ping support!', 5000)
    })
}
