import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Chevron } from '../../../../uiKit/icons/Icons'
import UserDialogPreview from '../UserDialogPreview/UserDialogPreview'

import { styles } from './styles'
import { isStringEmpty } from '../../../../helpers/isStringEmpty'

const DialogsSection = props => {
  const { classes, title, users, search } = props
  const [collapsed, setCollapsed] = useState(true)

  const filterUser = user => {
    if (user) {
      if (isStringEmpty(search)) return true
      return (
        user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
        user?.lastName.toLowerCase().includes(search.toLowerCase())
      )
    }
    return false
  }

  return (
    <div className={classes.container}>
      <div className={classes.title} onClick={() => setCollapsed(!collapsed)}>
        {title}
        {users && !users?.length ? (
          <span>&nbsp;0</span>
        ) : (
          <Chevron classes={classes.chevron} rotate={collapsed} />
        )}
      </div>

      {collapsed && (
        <div className={classes.dialogsContainer}>
          {users &&
            users
              ?.filter(filterUser)
              ?.map((user, index) => (
                <UserDialogPreview user={user} key={user.chatId + index} />
              ))}
        </div>
      )}
    </div>
  )
}

DialogsSection.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  users: PropTypes.array,
}

export default withStyles(styles)(DialogsSection)
