import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import './DateRange.css'

const DateRange = ({ setDate, startDate, endDate }) => {
  const saveChanges = (event, picker) => {
    setDate(picker.startDate, picker.endDate)
  }

  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      onApply={saveChanges}>
      <input
        type="text"
        name="daterange"
        value={`${startDate.format('YYYY-MM-DD')} - ${endDate.format(
          'YYYY-MM-DD',
        )}`}
      />
    </DateRangePicker>
  )
}
export default DateRange
