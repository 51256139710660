import React from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { styles } from './PopoverItemStyles'
import CheckIcon from '../../../../uiKit/icons/CheckIcon'
import PropTypes from 'prop-types'

const PopoverItem = props => {
  const { classes, label, showCheck, onClick } = props

  return (
    <div className={classes.filterItem} onClick={onClick}>
      <span>{showCheck && <CheckIcon />}</span> {label}
    </div>
  )
}

PopoverItem.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  showCheck: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles)(PopoverItem))
