import React, { useEffect, useState } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'
import { conditionGroup } from '../messages/AvailableMessages'
import AtomSelect from '../../../../uiKit/AtomSelect/AtomSelect'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import ConditionGroup from '../ConditionGroup/ConditionGroup'
import { PlusIcon } from '../../../../uiKit/icons/PlusIcon'
import SecondaryButton from '../../../../uiKit/SecondaryButton/SecondaryButton'
import Tooltip from '../../../../uiKit/tooltip/Tooltip'
import { usePrevious } from '../../../../hooks/usePrevious'
import { generateId } from '../../../../helpers/generateId'

const addIdToArrayAtConditionalRedirect = conditionalRedirect => {
  return {
    defaultRedirect: conditionalRedirect.defaultRedirect,
    conditionGroups: conditionalRedirect?.conditionGroups?.map(condition => {
      return {
        id: condition.id || generateId(),
        conjunction: condition.conjunction,
        redirectTo: condition.redirectTo,
        priority: condition.priority,
        expressions: condition.expressions.map(expression => {
          return {
            id: expression.id || generateId(),
            attribute: expression.attribute,
            operator: expression.operator,
            value: expression.value,
          }
        }),
      }
    }),
  }
}

const tooltipText =
    "Redirects user to a specific block. Click on 'Add condition group' to set redirects for a specific group of users."

const SmartRedirect = props => {
  const { classes, onDelete, updateMessage, message, saveTime } = props
  const [conditionalRedirect, setConditionalRedirect] = useState(
    addIdToArrayAtConditionalRedirect(message.conditionalRedirect),
  )
  const [touched, setTouched] = useState({ defaultRedirect: false })
  const prevSaveTime = usePrevious(saveTime)
  const isConditionGroups = !!conditionalRedirect?.conditionGroups?.length

  //needs to highlight every time when save button has been clicked
  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      const touched = {
        defaultRedirect: true,
      }

      setTouched(touched)
    }
  }, [saveTime])

  const handleUpdateDefaultRedirect = e => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.conditionalRedirect.defaultRedirect = e.value

    setConditionalRedirect({ ...messageCopy.conditionalRedirect })
    updateMessage(messageCopy)
  }

  const handleAddConditionGroup = () => {
    const messageCopy = deepCopyFunction(message)
    const newConditionGroup = deepCopyFunction(conditionGroup)
    newConditionGroup.id = generateId()
    newConditionGroup.priority = getMaxPriority()

    if (!messageCopy?.conditionalRedirect?.conditionGroups) {
      messageCopy.conditionalRedirect.conditionGroups = []
    }

    messageCopy.conditionalRedirect.conditionGroups.push(newConditionGroup)
    setConditionalRedirect({ ...messageCopy.conditionalRedirect })
    updateMessage(messageCopy)
  }

  const handleDeleteCondition = index => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.conditionalRedirect.conditionGroups.splice(index, 1)

    setConditionalRedirect({ ...messageCopy.conditionalRedirect })
    updateMessage(messageCopy)
  }

  const handleUpdateCondition = (condition, index) => {
    const messageCopy = deepCopyFunction(message)
    messageCopy.conditionalRedirect.conditionGroups[index] = condition

    setConditionalRedirect({ ...messageCopy.conditionalRedirect })
    updateMessage(messageCopy)
  }

  const updateTouched = (field, isTouched) => {
    const touchedCopy = deepCopyFunction(touched)
    touchedCopy[field] = isTouched

    setTouched(touchedCopy)
  }

  const sortOrder = (a, b) => {
    if (a.priority < b.priority) return -1
    if (a.priority > b.priority) return 1
    return 0
  }

  const getMaxPriority = () => {
    return message?.conditionalRedirect?.conditionGroups?.length
      ? message?.conditionalRedirect?.conditionGroups?.reduce((prev, current) =>
        (prev.priority > current.priority ? prev : current),
      ).priority + 1
      : 1
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>
          Smart redirect
          <Tooltip
            tooltipId={'tooltip-attribute'}
            tooltipText={tooltipText}
          />
        </span>
        {!isConditionGroups && (
          <SecondaryButton
            title={
              <span>
                <PlusIcon /> Add condition group
              </span>
            }
            onClick={handleAddConditionGroup}
            styles={{ border: 'none', padding: '0px', width: 160 }}
          />
        )}
      </div>
      <div className={classes.body}>
        {conditionalRedirect?.conditionGroups
          ?.sort(sortOrder)
          ?.map((condition, index) => (
            <ConditionGroup
              key={condition.id}
              condition={condition}
              updateCondition={condition =>
                handleUpdateCondition(condition, index)
              }
              onDelete={() => handleDeleteCondition(index)}
              saveTime={saveTime}
            />
          ))}
        {isConditionGroups && (
          <SecondaryButton
            title={
              <span>
                <PlusIcon /> Add condition group
              </span>
            }
            onClick={handleAddConditionGroup}
            styles={{ border: 'none', padding: '0px', width: 160 }}
          />
        )}
        <div className={isConditionGroups && classes.inputWrap}>
          <p className={classes.label}>
            Default redirect <span>*</span>
          </p>
          <AtomSelect
            value={conditionalRedirect?.defaultRedirect}
            onChange={handleUpdateDefaultRedirect}
            touched={touched.defaultRedirect}
            onFocus={() => updateTouched('defaultRedirect', true)}
          />
        </div>
      </div>
      {onDelete && (
        <i className={classes.deleteTemplate}>
          <IconButton
            className={classes.deleteIcon}
            aria-label="Delete"
            onClick={onDelete}>
            <TrashIcon width="20px" height="20px" color="#B0B2C0" />
          </IconButton>
        </i>
      )}
    </div>
  )
}

SmartRedirect.propTypes = {
  classes: PropTypes.object,
}

export default React.memo(withStyles(styles)(SmartRedirect))
