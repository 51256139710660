import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import {styles} from './FeedbackModalStyles'
import VotesLine from "./VotesLine/VotesLine";
import SubmitButton from "../../../../uiKit/buttons/SubmitButton";
import Modal from "../../../../uiKit/Modal";
import Loader from "../../../../uiKit/loaders/loader";
import Comment from "../../../analytics/components/Comment/Comment";
import {getFlowCommentsAmount, getFlowCommentsList} from "../../api/flow";

const FeedbackModal = props => {
    const {classes, isOpen, handleClose, selectedFlow, botId} = props;
    const [loader, setLoader] = useState(true);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month').add(1, 'day'));
    const [endDate, setEndDate] = useState(moment());
    const [upvotes, setUpvotes] = useState(0);
    const [downvotes, setDownvotes] = useState(0);
    const [comments, setComments] = useState([]);
    const [hasNext, setHasNext] = useState(true);
    const [page, setPage] = useState(0);
    const inputValue = `${startDate.format('YYYY-MM-DD')} - ${endDate.format('YYYY-MM-DD')}`;

    useEffect(() => {
        clearState();
        commentsRequest();
        commentsAmountRequest();
    }, [startDate, endDate]);

    const saveChanges = (event, picker) => {
        setStartDate(picker.startDate);
        setEndDate(picker.endDate);
    };

    const onCommentsScroll = ({target}) => {
        const isBottom = target.scrollHeight - target.scrollTop === target.clientHeight;

        if (isBottom && hasNext) {
            commentsRequest()
        }
    };

    const commentsRequest = () => {
        getFlowCommentsList(botId, selectedFlow.id, startDate, endDate, page)
            .then(res => {
                setComments([...comments, ...res.comments]);
                setHasNext(res.hasNext);
                setPage(page + 1);
            })
    };

    const commentsAmountRequest = () => {
        setLoader(true);
        getFlowCommentsAmount(botId, selectedFlow.id, startDate, endDate)
            .then(res => {
                setUpvotes(res.upvotes);
                setDownvotes(res.downvotes);
                setLoader(false)
            });
    };

    const clearState = () => {
        setPage(0);
        setComments([]);
        setHasNext(true);
        setUpvotes(0);
        setDownvotes(0);
    };

    return (
        <Modal
            open={isOpen}
            width={'460px'}
            padding={'40px 70px'}
            onClose={() => !loader && handleClose()}
            style={{padding: '30px 0px 0px', width: 460}}
            title={`${selectedFlow.name} Flow Feedbacks`}>
            {loader
                ? <div className={classes.loaderWrap} style={{height: 538}}>
                    <Loader/>
                </div>
                : <div>
                    <div className={classes.header}>
                        <div className={classes.pickerContainer}>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                style={{width: '100%'}}
                                onApply={saveChanges}>
                                <input type="text"
                                       name="daterange"
                                       value={inputValue}/>
                            </DateRangePicker>
                        </div>

                        <VotesLine upvotes={upvotes}
                                   downvotes={downvotes}/>
                    </div>

                    {comments.length
                        ? <div className={classes.commentsWrap} onScroll={onCommentsScroll}>
                            {comments.map(comment => (
                                <div key={comment.messageId}>
                                    <Comment data={comment}/>
                                </div>
                            ))}
                        </div>
                        : <div className={classes.noDataWrap}>
                            <p className={classes.noData}>No data</p>
                        </div>
                    }

                    <div className={classes.footer}>
                        <SubmitButton onClick={handleClose} title='Close'/>
                    </div>
                </div>
            }
        </Modal>
    );
};

export default withRouter(withStyles(styles)(FeedbackModal));
