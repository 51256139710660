export const styles = () => ({
  container: {},
})

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 100,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: '#ffffff',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#1658F3',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#1658F3',
    cursor: 'pointer',
    '&:hover': {
      color: '#1658F3',
    },
  }),
}
