import { addNewFlow, saveFlows } from '../actions/flows'
import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { BASE_URL } from '../../../configs'
import {FLOW_COMMENTS_SIZE} from "../../../constants/supportMessagesSize"

export function getFlows(botId, showFeedback) {
  const url = showFeedback
      ? BASE_URL + '/bot/' + botId + '/flow'.addQueryStringParameter('showFeedback', showFeedback)
      : BASE_URL + '/bot/' + botId + '/flow';
  fetch(url, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveFlows(json)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support!')
    })
}

export function createNewFlow(botId, flow) {
  fetch(BASE_URL + '/bot/' + botId + '/flow', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(flow),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Flow is created successfully.')
      addNewFlow(json)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function deleteFlow(flowId, botId, callback) {
  fetch(BASE_URL + '/bot/' + botId + '/flow/' + flowId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Flow is deleted successfully.')
      getFlows(botId)
      callback()
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function changeFlowStatus(flowId, botId, disable) {
  fetch(BASE_URL + '/flow/' + flowId + '?disable=' + disable, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Flow status is changed successfully.')
      getFlows(botId)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function getFlowCommentsAmount(botId, flowId, fromDate, toDate) {
  return fetch(`${BASE_URL}/bot/${botId}/flow/${flowId}/feedback?from=${fromDate.format('YYYY-MM-DD')}&to=${toDate.format('YYYY-MM-DD')}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "GET"
  }).then((response) => logoutOnRequestOrResponseJson(response))
      .catch(() => alertError("Fail. Try it later"));
}

export function getFlowCommentsList(botId, flowId, fromDate, toDate, page) {
  return fetch(`${BASE_URL}/bot/${botId}/flow/${flowId}/feedback/comments?page=${page}&size=${FLOW_COMMENTS_SIZE}&from=${fromDate.format('YYYY-MM-DD')}&to=${toDate.format('YYYY-MM-DD')}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "GET"
  }).then((response) => logoutOnRequestOrResponseJson(response))
      .catch(() => alertError("Fail. Try it later"));
}
