import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import Buttons from '../Buttons/Buttons'
import { styles } from './styles'

const GenericMessage = props => {
  const { classes, element, isRtl } = props

  return (
    <div className={classes.container}>
      <div
        className={classes.img}
        style={{ backgroundImage: 'url(' + element?.imageUrl + ')' }}
      />

      <div
        className={
          element?.buttons?.length || element?.subtitle
            ? classes?.textButtons
            : classes?.text
        }
        style={{ textAlign: isRtl && 'end' }}>
        {element?.title}
      </div>

      {element?.subtitle && (
        <div
          className={
            element?.buttons?.length
              ? classes?.subtitleButtons
              : classes?.subtitle
          }
          style={{ textAlign: isRtl && 'end' }}>
          {element?.subtitle}
        </div>
      )}

      {element?.buttons && !!element?.buttons?.length && (
        <Buttons buttons={element?.buttons} />
      )}
    </div>
  )
}

GenericMessage.propTypes = {
  classes: PropTypes.object,
  element: PropTypes.object,
  isRtl: PropTypes.bool,
}

export default withStyles(styles)(GenericMessage)
