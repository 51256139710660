export const styles = {
  filterItem: {
    height: 28,
    color: '#3A3F62',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#EBEBEF',
    },
    '& span': {
      width: 36,
      textAlign: 'center',
    },
  },
}
