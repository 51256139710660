export const styles = () => ({
  container: {
    display: 'flex',
    transform: 'translateZ(0)',
    '-webkit-text-size-adjust': 'none',
    width: '100%',
    position: 'relative',
    '&:hover span': {
      visibility: 'visible',
    },
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1,
    border: '1px solid #e5e5e5',
    backgroundColor: '#fff',
    overflow: 'auto',
    pointerEvents: 'none',
    transition: 'transform 1s',
    width: '100%',
    lineHeight: '20px',
    fontSize: '14px',
  },
  highlights: {
    padding: 10,
    lineHeight: '20px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    '&>span': {
      display: 'inline',
    },
  },
  textarea: {
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    margin: 0,
    border: '1px solid #e5e5e5',
    color: 'transparent',
    backgroundColor: 'transparent',
    overflow: 'auto',
    resize: 'none',
    transition: 'transform 1s',
    outline: 'none',
    padding: 10,
    lineHeight: '20px',
    caretColor: '#000000',
    fontFamily: "'Lato', sans-serif",
    wordWrap: 'break-word',
  },
  icon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    backgroundColor: 'rgba(53, 64, 82, 0.7)',
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    visibility: 'hidden',
    bottom: 4,
    right: 4,
    outline: 'none',
  },
})
