import React from 'react'

import TabTemplate from '../../../../widget/components/TabTemplate'
import PropTypes from 'prop-types'
import { WhatAppIcon } from '../../../../../uiKit/icons/WhatAppIcon'

const WhatAppTabTemplateView = props => {
  const { tabExpanded, onChange, children } = props
  return (
    <TabTemplate
      tab={{
        name: 'WhatsApp',
        icon: <WhatAppIcon />,
        description: 'Connect the chatbot to your WhatApp page',
      }}
      expanded={tabExpanded}
      onChange={onChange}>
      {children}
    </TabTemplate>
  )
}

WhatAppTabTemplateView.propTypes = {
  botId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  tabExpanded: PropTypes.bool.isRequired,
}

export default WhatAppTabTemplateView
