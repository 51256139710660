export const styles = () => ({
  img: {
    width: 328,
    height: 150,
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#949494',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
  },
  text: {
    background: '#FFFFFF',
    borderRadius: '0px 0px 8px 8px',
    padding: '6px 12px 12px',
    fontWeight: 300,
    width: 328,
  },
  textButtons: {
    background: '#FFFFFF',
    padding: '6px 12px 12px',
    fontWeight: 300,
    width: 328,
  },
  subtitle: {
    background: '#FFFFFF',
    borderRadius: '0px 0px 8px 8px',
    padding: '0px 12px 12px',
    fontWeight: 300,
    fontSize: 14,
    width: 328,
    color: '#828282',
  },
  subtitleButtons: {
    background: '#FFFFFF',
    padding: '0px 12px 12px',
    fontWeight: 300,
    fontSize: 14,
    width: 328,
    color: '#828282',
  },
  link: {
    background: '#FFFFFF',
    padding: '4px 12px',
    fontSize: 14,
    width: 328,
    display: 'block',
  },
  container: {
    border: '1px solid #E5E5E5',
    borderRadius: 8,
    height: 'fit-content',
  },
})
