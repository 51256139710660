import { BACKEND_URL, BASE_URL } from '../../../configs'
import { createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'

export function login(botId, projectId) {
  fetch(`${BASE_URL}/configs/dialogflow`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response =>
      window.open(
        `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${
          BACKEND_URL + '/dialogflow/auth'
          // eslint-disable-next-line max-len
        }&scope=https://www.googleapis.com/auth/cloud-platform+https://www.googleapis.com/auth/dialogflow+https://www.googleapis.com/auth/userinfo.email&response_type=code&client_id=${
          response.clientId
        }&access_type=offline&state=${botId + ',' + projectId}`,
        'Login with Dialogflow',
        'width=560,height=600,toolbar=0,menubar=0,location=0',
      ),
    )
}
