import React from 'react'
import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Warning } from '@material-ui/icons'

import { PlusIcon, TrashIcon } from '../../../../uiKit/icons/Icons'
import { USE_MESSAGE_KEYS } from '../../../../configs'
import MessageButton from '../messages/MessageButton'
import MessageQuickReplies from '../messages/MessageQuickReplies'
import InputWithParams from '../../../../uiKit/InputWithParams/InputWithParams'
import { styles } from './styles'

const TextMessage = props => {
  const { classes, message, updateMessage, onDelete, rtl, scrollBlock } = props

  const handleChangeName = value => {
    const newMessage = JSON.parse(JSON.stringify(message))
    newMessage.name = value
    updateMessage(newMessage)
  }

  const handleChangeText = (index, value) => {
    const newMessage = JSON.parse(JSON.stringify(message))
    newMessage.texts[index] = value
    updateMessage(newMessage)
  }

  const updateBtn = (btn, btnIndex) => {
    const newMessage = JSON.parse(JSON.stringify(message))
    newMessage.buttons[btnIndex] = btn
    updateMessage(newMessage)
  }

  const deleteButton = btnIndex => {
    const newMessage = JSON.parse(JSON.stringify(message))
    newMessage.buttons.splice(btnIndex, 1)
    updateMessage(newMessage)
  }

  const addNewButton = () => {
    const newMessage = JSON.parse(JSON.stringify(message))
    if (newMessage.buttons == null) newMessage.buttons = []

    newMessage.buttons.push({
      type: 'postback',
      title: '',
      payload: '',
    })
    updateMessage(newMessage)
  }

  const handleDeleteText = index => {
    const newMessage = JSON.parse(JSON.stringify(message))
    newMessage.texts.splice(index, 1)
    updateMessage(newMessage)
  }

  const addAddTextField = () => {
    const newMessage = JSON.parse(JSON.stringify(message))
    newMessage.texts.push('')
    updateMessage(newMessage)
  }

  return (
    <div className={classes.container}>
      <div className={classes.textFieldsWrap}>
        <div className={classes.message}>
          {onDelete && !message?.isHardCoded && (
            <i className={classes.deleteTemplate}>
              <IconButton
                className={classes.deleteIcon}
                aria-label="Delete"
                onClick={onDelete}>
                <TrashIcon width="20px" height="20px" color="#B0B2C0" />
              </IconButton>
            </i>
          )}

          {message?.isHardCoded && (
            <Tooltip
              id="tooltip-bottom-start"
              title="This message is being used in code. Don't change the name!"
              placement="bottom-start"
              className={classes.tooltip}>
              <Warning style={{ marginLeft: 15 }} />
            </Tooltip>
          )}

          {USE_MESSAGE_KEYS && (
            <input
              value={message.name}
              placeholder="Message key"
              className={classes.keyInput}
              onChange={event => handleChangeName(event.target.value)}
            />
          )}

          <InputWithParams
            title="Text message"
            onChange={value => handleChangeText(0, value)}
            value={message.texts[0]}
            placeholder="Input text..."
            disableBorderRadius={true}
            rtl={rtl}
            scrollBlock={scrollBlock}
          />

          <div className={classes.buttonsContainer}>
            {message?.buttons?.map((btn, index) => (
              <MessageButton
                btn={btn}
                key={index}
                last={index === 2}
                rtl={rtl}
                onUpdate={btn => updateBtn(btn, index)}
                onDelete={() => deleteButton(index)}
              />
            ))}
            {(message.buttons == null || message.buttons.length < 3) && (
              <div className={classes.addButton} onClick={addNewButton}>
                Add button
              </div>
            )}
          </div>
        </div>

        {message.texts
          .filter((elem, index) => index !== 0)
          .map((elem, index) => (
            <div className={classes.textWrap} key={index}>
              <i
                className={classes.deleteIconWrap}
                onClick={() => handleDeleteText(index + 1)}>
                <IconButton className={classes.deleteText} aria-label="Delete">
                  <TrashIcon width="20px" height="20px" color="#B0B2C0" />
                </IconButton>
              </i>
              <InputWithParams
                title="Text message"
                onChange={value => handleChangeText(index + 1, value)}
                value={message.texts[index + 1]}
                style={{ borderRadius: 10 }}
                placeholder="Input text..."
                scrollBlock={scrollBlock}
              />
            </div>
          ))}
        <div className={classes.plusIconWrap} onClick={addAddTextField}>
          <PlusIcon width="20px" height="20px" color="grey" />
        </div>
      </div>
      <MessageQuickReplies
        message={message}
        updateMessage={updateMessage}
        rtl={rtl}
      />
    </div>
  )
}

TextMessage.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.object,
  updateMessage: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withStyles(styles)(TextMessage)
