import React from 'react'
import ReactTooltip from 'react-tooltip'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  gridItem: {
    height: 10,
    width: '100%',
    marginBottom: 5,
  },
  tooltip: {
    border: '1px solid #EBEBEF',
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
    background: '#ffffff',
  },
})

const DayModel = ({ classes, dayModel, color, day, getDayData }) =>
  dayModel.map(model => {
    const { opacity: colorOpacity, amount } = getDayData(
      day.dayToAmount,
      day.dayOfWeek,
      model.time,
    )
    const gridItemStyle = color
      ? {
        backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${
          colorOpacity / 100
        })`,
      }
      : {}

    return (
      <div
        key={model.time}
        data-tip
        data-for={`${day.dayOfWeek}-tooltip-${model.time}`}
        style={gridItemStyle}
        className={classes.gridItem}>
        <ReactTooltip
          className={classes.tooltip}
          place="top"
          type="light"
          effect="solid"
          id={`${day.dayOfWeek}-tooltip-${model.time}`}>
          <span>{amount}</span>
        </ReactTooltip>
      </div>
    )
  })

export default withStyles(styles, { withTheme: true })(DayModel)
