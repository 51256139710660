/**
 * Created by Admin on 25.04.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getWidget, updateWidget } from '../api/widget'
import { withStyles } from '@material-ui/core'
import Headline from '../../../uiKit/texts/Headline'
import Customization from './tabs/Customization'
import Delay from './tabs/Delay'
import WelcomeScreen from './tabs/WelcomeScreen'
import WidgetConnection from './tabs/WidgetConnection'
import { getBotLanguages } from '../../settings/api/settings'

const styles = theme => ({
  wrapHeader: {
    display: 'flex',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: theme.palette.text.gray,
    marginBottom: 0,
  },
  name: {
    fontFamily: 'Lato',
    fontSize: 18,
    marginBottom: 0,
    color: theme.palette.text.header,
  },
  mainWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftContainer: {
    flexBasis: '60%',
  },
})
class WidgetGenerator extends React.Component {
  state = {
    websiteUrl: '',
    expanded: null,
  }

  componentWillMount() {
    getWidget(this.props.match.params.botId, 'en')
  }

  componentDidMount() {
    this.setLanguageOptions(this.props.match.params.botId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.botWidget.length !== 0) {
      this.setState({
        widget: nextProps.botWidget,
      })
    }
  }

  setLanguageOptions = botId => {
    getBotLanguages(botId).then(botLanguages => {
      if (botLanguages.defaultLanguage) {
        const { defaultLanguage, activeLanguages } = botLanguages
        const selectedOption = {
          label: defaultLanguage.fullName,
          value: defaultLanguage.shortName,
          rtl: defaultLanguage.isRtl,
        }

        const languageOptions = activeLanguages.map(language => {
          return {
            label: language.fullName,
            value: language.shortName,
            rtl: language.isRtl,
          }
        })

        this.setState({
          languageOptions: languageOptions,
          selectedLanguageOption: selectedOption,
        })
      }
    })
  }

  handleSelectedLanguage = selectedLanguage => {
    if (this.state.selectedLanguageOption !== selectedLanguage) {
      this.setState({
        selectedLanguageOption: selectedLanguage,
      })

      const newWidget = getWidget(
        this.props.match.params.botId,
        selectedLanguage.value,
      )
      this.setState({
        widget: newWidget,
      })
    }
  }

  handleChange = (value, name) => {
    const widget = { ...this.state.widget }

    if (value === undefined || value === null || widget[name] === value) {
      return
    } else if (typeof value === 'string' && widget[name] === value.trim()) {
      return
    } else if (!isNaN(widget[name]) && widget[name] === +value) {
      return
    }

    widget[name] = value
    this.setState(
      {
        widget,
      },
      () => {
        updateWidget(
          this.props.activeBot.id,
          widget,
          this.state.selectedLanguageOption.value,
        )
      },
    )
  }

  handleChangeExpansion = expanded => {
    this.setState({
      expanded: expanded === this.state.expanded ? null : expanded,
    })
  }

  render() {
    const { classes } = this.props
    const { expanded, widget, languageOptions, selectedLanguageOption } =
      this.state
    return (
      <div>
        <Headline title="Chatbot Widget" />
        {this.state.widget !== undefined && (
          <div className={classes.mainWrap}>
            <div className={classes.leftContainer}>
              <Customization
                handleChange={(value, name) => {
                  this.handleChange(value, name)
                }}
                handleChangeExpansion={() => {
                  this.handleChangeExpansion('panel1')
                }}
                expanded={expanded === 'panel1'}
                widget={widget}
              />
              <Delay
                handleChange={(value, name) => {
                  this.handleChange(value, name)
                }}
                handleChangeExpansion={() =>
                  this.handleChangeExpansion('panel2')
                }
                expanded={expanded === 'panel2'}
                widget={widget}
              />
              <WelcomeScreen
                handleChange={(value, name) => {
                  this.handleChange(value, name)
                }}
                handleChangeExpansion={() =>
                  this.handleChangeExpansion('panel3')
                }
                expanded={expanded === 'panel3'}
                widget={widget}
                languageOptions={languageOptions}
                selectedLanguageOption={selectedLanguageOption}
                handleSelectedLanguage={this.handleSelectedLanguage}
              />
              <WidgetConnection
                handleChange={(value, name) => {
                  this.handleChange(value, name)
                }}
                handleChangeExpansion={() =>
                  this.handleChangeExpansion('panel4')
                }
                expanded={expanded === 'panel4'}
                widget={widget}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

WidgetGenerator.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  botWidget: state.botWidget,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(WidgetGenerator),
  ),
)
