import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { styles } from './CommentsListStyles'
import { getComments } from '../../api'
import {
  getSortingParams,
  saveSortBy,
  saveSortDirection,
  SIZE,
} from './CommentsListConfig'
import CommentsFilter from '../CommentsFilter/CommentsFilter'
import Comment from '../Comment/Comment'
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall'

const CommentsList = props => {
  const { classes, startDate, endDate, selectedBar } = props
  const { sortDirectionSaved, sortBySaved } = getSortingParams()
  const [sortDirection, setSortDirection] = useState(
    sortDirectionSaved || 'desc',
  )
  const [sortBy, setSortBy] = useState(sortBySaved || 'date')
  const [page, setPage] = useState(0)
  const [commentsList, setCommentsList] = useState([])
  const [hasNext, setHasNext] = useState(true)
  const [loading, setLoader] = useState(false)

  useEffect(() => {
    clearState()
    handleRequests(0, [], true, selectedBar)
  }, [sortBy, sortDirection, startDate, endDate, selectedBar])

  const handleScroll = ({ target }) => {
    const isBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight

    if (isBottom && hasNext) {
      handleRequests(page, commentsList, false)
    }
  }

  const handleRequests = (page, commentsList, loading, score) => {
    const { botId } = props.match.params
    if (loading) setLoader(true)
    getComments(
      botId,
      startDate,
      endDate,
      page,
      SIZE,
      sortBy,
      sortDirection,
      score,
    )
      .then(res => {
        setCommentsList([...commentsList, ...res.feedbacks])
        setHasNext(res.hasNext)
        setPage(page + 1)
      })
      .then(() => setLoader(false))
      .catch(err => console.log(err))
  }

  const handleSortBy = sortBy => {
    saveSortBy(sortBy)
    setSortBy(sortBy)
  }

  const handleSortDirection = sortDirection => {
    saveSortDirection(sortDirection)
    setSortDirection(sortDirection)
  }

  const clearState = () => {
    setPage(0)
    setCommentsList([])
    setHasNext(true)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <CommentsFilter
          sortDirection={sortDirection}
          setSortDirection={handleSortDirection}
          sortBy={sortBy}
          setSortBy={handleSortBy}
        />
      </div>
      {loading ? (
        <div className={classes.loaderContainer}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <div className={classes.commentsContainer} onScroll={handleScroll}>
          {commentsList &&
            commentsList.map(comment => (
              <Comment key={comment.messageId} data={comment} />
            ))}
        </div>
      )}
    </div>
  )
}

CommentsList.propTypes = {
  classes: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  selectedBar: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(CommentsList))
