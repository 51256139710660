import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import TextMessage from '../../components/TextMessage/TextMessage'
import Template from '../messages/Template'
import Attachment from '../messages/Attachment'
import RandomTextMessage from '../messages/RandomTextMessage'
import RandomAttachment from '../messages/RandomAttachment'
import Typing from '../messages/Typing'
import UserInput from '../messages/UserInput'
import SetAttribute from '../SetAttribute/SetAttribute'
import SaveUserInput from '../SaveUserInput/SaveUserInput'
import SmartRedirect from '../SmartRedirect/SmartRedirect'
import {
  CONDITIONAL_REDIRECT_TYPE,
  RANDOM_REDIRECT_TYPE,
  SAVE_USER_INPUT_TYPE,
  SET_ATTRIBUTE_TYPE,
  TEXT_TYPE,
  USER_INPUT_TYPE,
} from '../../constants/messageTypes'
import RandomAtoms from '../RandomAtoms/RandomAtoms'

const MessagesWrap = props => {
  const {
    message,
    rtl,
    index,
    updateMessage,
    deleteMessage,
    scrollBlock,
    saveTime,
  } = props

  return (
    <>
      {message.texts && (
        <TextMessage
          message={message}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
          rtl={rtl}
          scrollBlock={scrollBlock}
        />
      )}
      {message.attachment != null &&
        (message.attachment.type === 'template' ? (
          <Template
            message={message}
            rtl={rtl}
            updateMessage={message => updateMessage(message, index)}
            onDelete={() => deleteMessage(message)}
            scrollBlock={scrollBlock}
          />
        ) : (
          <Attachment
            message={message}
            rtl={rtl}
            updateMessage={message => updateMessage(message, index)}
            onDelete={() => deleteMessage(message)}
          />
        ))}
      {message.random != null && message.type === TEXT_TYPE && (
        <RandomTextMessage
          message={message}
          rtl={rtl}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
          scrollBlock={scrollBlock}
        />
      )}
      {message.random != null && message.type === 'attachment' && (
        <RandomAttachment
          message={message}
          rtl={rtl}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
        />
      )}
      {message?.senderAction === 'typing_on' && (
        <Typing
          message={message}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
        />
      )}
      {message.type === USER_INPUT_TYPE && (
        <UserInput
          message={message}
          rtl={rtl}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
        />
      )}
      {message.type === SET_ATTRIBUTE_TYPE && (
        <SetAttribute
          message={message}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
        />
      )}
      {message.type === SAVE_USER_INPUT_TYPE && (
        <SaveUserInput
          message={message}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
          saveTime={saveTime}
        />
      )}
      {message.type === CONDITIONAL_REDIRECT_TYPE && (
        <SmartRedirect
          message={message}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
          saveTime={saveTime}
        />
      )}
      {message.type === RANDOM_REDIRECT_TYPE && (
        <RandomAtoms
          message={message}
          updateMessage={message => updateMessage(message, index)}
          onDelete={() => deleteMessage(message)}
          saveTime={saveTime}
        />
      )}
    </>
  )
}
MessagesWrap.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(MessagesWrap)
