import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import AttributesList from '../../uiKit/AttributesList/AttributesList'
import { AttributeIcon } from '../icons/AttributeIcon'
import { getAttributeColor } from '../../helpers/getAttributeColor'
import { createAttribute } from '../../tabs/settings/api/attributes'

import { styles } from './styles'
import {
  ARROW_DOWN_KEY,
  ARROW_LEFT_KEY,
  ARROW_RIGHT_KEY,
  ARROW_UP_KEY,
  BACKSPACE_KEY,
  DELETE_KEY,
  ENTER_KEY,
  SPACE_KEY,
} from '../../constants/keyCodes'
import {
  ASTERISK,
  ATTRIBUTE_REGEX,
  ATTRIBUTE_REGEX_INCLUDE,
  CLOSE_BRACKET,
  CLOSE_PAIR_BRACKET,
  container,
  EMPTY_BRACKETS,
  OPEN_BRACKET,
  OPEN_PAIR_BRACKET,
  NOT_ASTERISK_REGEX,
  ATTRIBUTE_REGEX_OR_OPEN_BRACKETS,
} from './config'
import { usePrevious } from '../../hooks/usePrevious'
import { regexIndexOf } from '../../helpers/regexIndexOf'

const InputWithParams = props => {
  const {
    classes,
    rtl,
    value,
    placeholder,
    onChange,
    height,
    disableBorderRadius,
    attributes,
    activeBotId,
    scrollBlock,
  } = props

  const [show, setShow] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [currentValue, setCurrentValue] = useState(null)
  const [searchValue, setSearchValue] = useState(EMPTY_BRACKETS)
  const [selectedAttribute, setSelectAttribute] = useState(1)
  const [caretPosition, setCaretPosition] = useState(0)

  const prevShow = usePrevious(show)

  const backdrop = useRef(null)
  const highlights = useRef(null)
  const textarea = useRef(null)
  const attributeIcon = useRef(null)
  const attributeList = useRef(null)

  useEffect(() => {
    if (highlights?.current) {
      highlights.current.innerHTML = applyHighlights(textarea.current.value)
    }
  }, [value, attributes])

  useEffect(() => {
    if (scrollBlock?.current?.style?.overflowY)
      scrollBlock.current.style.overflowY = show ? 'hidden' : 'scroll'
    if (prevShow && show !== prevShow) {
      textarea.current.focus()
      if (caretPosition) updateCursorPosition(caretPosition)
    }

    setSearchValue(EMPTY_BRACKETS)
    setSelectAttribute(1)
  }, [show])

  const handleScroll = event => {
    backdrop.current.scrollTop = event.target.scrollTop
    backdrop.current.scrollLeft = event.target.scrollLeft
  }

  const handleInput = event => {
    const text = event.target.value
    const selectionStart = textarea.current.selectionStart
    const selectionEnd = textarea.current.selectionEnd

    highlights.current.innerHTML = applyHighlights(text)

    //open attributes list when '{{' was typed
    if (
      text[selectionStart - 1] === OPEN_BRACKET &&
      text[selectionStart - 2] === OPEN_BRACKET
    ) {
      handleShowAttributes(false)
    }

    //close attributes list when '{' was removed
    if (
      text[selectionStart - 1] === OPEN_BRACKET &&
      text[selectionStart - 2] !== OPEN_BRACKET
    ) {
      setCaretPosition(selectionEnd)
      setShow(false)
    }

    //create new attribute when '}}' was typed
    if (
      text[selectionEnd - 1] === CLOSE_BRACKET &&
      text[selectionEnd - 2] === CLOSE_BRACKET &&
      text[selectionEnd - 3] !== CLOSE_BRACKET
    ) {
      const subString = text.substring(
        text.lastIndexOf(OPEN_PAIR_BRACKET) + 2,
        selectionEnd - 2,
      )
      const isNewAttribute = !attributes.some(
        attribute => attribute.name === subString,
      )

      if (!subString.includes(CLOSE_PAIR_BRACKET) && isNewAttribute) {
        handleCreateAttribute(subString)
      } else {
        setShow(false)
      }
    }

    handleSearch(text)
  }

  const handleSearch = text => {
    const caretPosition = textarea.current.selectionStart
    const noAttributesText = text?.replace(ATTRIBUTE_REGEX, EMPTY_BRACKETS)
    const arrowIndex = noAttributesText.indexOf(OPEN_PAIR_BRACKET)
    const textStart = text.substring(0, caretPosition)
    const noAttributeTextStartLength = textStart
      .replace(ATTRIBUTE_REGEX, EMPTY_BRACKETS)
      .substring(0, caretPosition).length
    const newSearchValue = noAttributesText.substring(
      arrowIndex,
      caretPosition - (textStart.length - noAttributeTextStartLength),
    )

    if (
      newSearchValue.includes(OPEN_PAIR_BRACKET) &&
      !newSearchValue.includes(CLOSE_BRACKET)
    ) {
      setSearchValue(newSearchValue.replace(OPEN_PAIR_BRACKET, EMPTY_BRACKETS))
    }
  }

  const handleArrowDown = event => {
    const key = event.keyCode || event.charCode

    if (key === ARROW_DOWN_KEY) {
      event.preventDefault()

      if (show) {
        updateAttributeListScroll(ARROW_DOWN_KEY)
      }
    }
  }

  const handleArrowUp = event => {
    const key = event.keyCode || event.charCode

    if (key === ARROW_UP_KEY) {
      event.preventDefault()

      if (show) {
        updateAttributeListScroll(ARROW_UP_KEY)
      }
    }
  }

  const handleEnter = event => {
    const key = event.keyCode || event.charCode

    if (key === ENTER_KEY && show) {
      event.preventDefault()

      if (
        attributeList?.current?.childNodes[selectedAttribute]?.innerText ||
        searchValue
      ) {
        const attributeName =
          attributeList?.current?.childNodes[selectedAttribute]?.innerText ||
          searchValue
        addParam(attributeName)
      }
    }
  }

  const handleLeftArrow = event => {
    const key = event.keyCode || event.charCode
    const prevChar =
      event.target.value &&
      event.target.value[textarea.current.selectionStart - 1]

    if (key === ARROW_LEFT_KEY && show) {
      event.preventDefault()
    }

    if (key === ARROW_LEFT_KEY && prevChar === CLOSE_BRACKET) {
      event.preventDefault()

      const closestBracketsIndex = event.target.value
        .substring(0, textarea.current.selectionStart)
        .lastIndexOf(OPEN_PAIR_BRACKET)

      updateCursorPosition(closestBracketsIndex)
    }
  }

  const handleRightArrow = event => {
    const key = event.keyCode || event.charCode
    const nextChar =
      event.target.value &&
      event.target.value[textarea.current.selectionStart + 1]

    if (key === ARROW_RIGHT_KEY && show) {
      event.preventDefault()
    }

    if (key === ARROW_RIGHT_KEY && nextChar === OPEN_BRACKET) {
      event.preventDefault()

      const stringStart = event.target.value.substring(
        0,
        textarea.current.selectionStart,
      )

      const closestBracketsIndex =
        event.target.value
          .substring(textarea.current.selectionStart, event.target.value.length)
          .indexOf(CLOSE_PAIR_BRACKET) +
        stringStart.length +
        2

      updateCursorPosition(closestBracketsIndex)
    }
  }

  const handleSpace = event => {
    const key = event.keyCode || event.charCode
    const prevChar =
      event.target.value &&
      event.target.value[textarea.current.selectionStart - 1]

    if (!showSearch && prevChar === OPEN_BRACKET && key === SPACE_KEY) {
      event.preventDefault()
    }
  }

  const onKeyDown = event => {
    handleSpace(event)

    handleArrowDown(event)

    handleArrowUp(event)

    handleEnter(event)

    handleLeftArrow(event)

    handleRightArrow(event)

    setCurrentValue(event.target.value)
  }

  const updateAttributeListScroll = direction => {
    let selectedChildNodeIndex
    const childNodes = attributeList?.current?.childNodes
    const directionStep = direction === ARROW_UP_KEY ? -1 : 1
    const childNodesLength = childNodes?.length - 1

    //change index of highlighted item
    if (childNodes[selectedAttribute + directionStep]) {
      selectedChildNodeIndex = selectedAttribute + directionStep
    } else {
      selectedChildNodeIndex = direction === ARROW_UP_KEY ? childNodesLength : 1
    }

    //make one step ahead when childNode is not item
    if (childNodes[selectedChildNodeIndex].nodeName !== 'DIV') {
      selectedChildNodeIndex =
        selectedChildNodeIndex + directionStep < 1
          ? childNodesLength
          : selectedChildNodeIndex + directionStep
    }

    const selectedChildNode = childNodes[selectedChildNodeIndex]

    selectedChildNode.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    })
    setSelectAttribute(selectedChildNodeIndex)
  }

  //function to remove whole attribute by 'delete' or 'backspace'
  const onKeyUp = event => {
    const key = event.keyCode || event.charCode
    const position = textarea.current.selectionStart
    const placeholder = ATTRIBUTE_REGEX

    if (key === DELETE_KEY || key === BACKSPACE_KEY) {
      for (let match; (match = placeholder.exec(currentValue)) !== null;) {
        if (match.index < position + 1 && placeholder.lastIndex > position) {
          onChange(
            currentValue.substr(0, match.index) +
              currentValue.substring(
                placeholder.lastIndex,
                currentValue.length,
              ),
          )
          const caretPosition = currentValue.substr(0, match.index).length

          //need this to keep cursor position
          setTimeout(() => updateCursorPosition(caretPosition), 0)
        }
      }
    }
  }

  const addParam = attributeName => {
    const position = textarea.current.selectionStart
    const stringStart = value.slice(0, position)
    const lastBracketIndex = stringStart.lastIndexOf(OPEN_PAIR_BRACKET)
    const start = showSearch
      ? stringStart
      : stringStart.slice(0, lastBracketIndex)
    const end = value.slice(position)
    const text = `${start}{{${attributeName}}}${end}`
    const caretPosition =
      position +
      (attributeName?.length - searchValue.length) +
      (showSearch ? 4 : 2)

    setCaretPosition(caretPosition)
    onChange(text)
    setShow(false)
  }

  const handleShowAttributes = focus => {
    setShow(true)
    setShowSearch(focus)
  }

  const handleCreateAttribute = attributeName => {
    createAttribute(activeBotId, { name: attributeName }).then(() =>
      setShow(false),
    )
  }

  const getPopupPosition = () => {
    const buttonPos = attributeIcon?.current?.getBoundingClientRect()

    const highlightsPos =
      highlights?.current?.children &&
      Object.values(highlights?.current?.children)
        ?.find(value => value?.nodeName === 'SPAN')
        ?.getBoundingClientRect()

    const isEnoughSpace =
      buttonPos?.top + buttonPos?.height + container.height > window.innerHeight

    const topFromButton = isEnoughSpace
      ? buttonPos?.top + buttonPos?.height - container.height + 'px'
      : buttonPos?.top + buttonPos?.height + 'px'

    const leftFromButton = isEnoughSpace
      ? buttonPos?.left + buttonPos?.width + 'px'
      : buttonPos?.left + buttonPos?.width - container.width + 'px'

    const topFromTextarea = highlightsPos?.top + 20 + 'px'

    const leftTextarea = highlightsPos?.left + 10 + 'px'

    return {
      top: showSearch ? topFromButton : topFromTextarea,
      left: showSearch ? leftFromButton : leftTextarea,
    }
  }

  const handleCloseAttributeList = () => {
    const text = value
      .split(ATTRIBUTE_REGEX_INCLUDE)
      .map(removeExtraBrackets)
      .join(EMPTY_BRACKETS)

    onChange(text)
    setShow(false)
  }

  const removeExtraBrackets = str => {
    if (!str.endsWith(CLOSE_PAIR_BRACKET)) {
      return str.replace(`{{${searchValue}`, EMPTY_BRACKETS)
    }
    return str
  }

  const updateCursorPosition = caretPosition => {
    textarea.current.selectionStart = caretPosition
    textarea.current.selectionEnd = caretPosition
  }

  const handleSkipAttribute = () => {
    const updatedValue = value.replaceAll(ATTRIBUTE_REGEX_INCLUDE, match => {
      return match.replace(/[^{}]/g, ASTERISK)
    })

    const prevChar = updatedValue[textarea.current.selectionStart - 1]
    const nextChar = updatedValue[textarea.current.selectionStart]

    if (
      [ASTERISK, CLOSE_BRACKET, OPEN_BRACKET].includes(prevChar) &&
      [ASTERISK, CLOSE_BRACKET, OPEN_BRACKET].includes(nextChar)
    ) {
      const endText = updatedValue.substring(
        textarea.current.selectionStart,
        updatedValue.length,
      )
      let caretPosition = updatedValue.substring(
        0,
        textarea.current.selectionStart,
      ).length

      if (
        regexIndexOf(endText, NOT_ASTERISK_REGEX, 0) > 0 ||
        (prevChar === ASTERISK && nextChar === CLOSE_BRACKET)
      ) {
        caretPosition += regexIndexOf(endText, NOT_ASTERISK_REGEX, 0) + 2
      } else if (prevChar === OPEN_BRACKET && nextChar === OPEN_BRACKET) {
        caretPosition += regexIndexOf(endText, NOT_ASTERISK_REGEX, 2) + 2
      } else if (prevChar === CLOSE_BRACKET && nextChar === CLOSE_BRACKET) {
        caretPosition += regexIndexOf(endText, NOT_ASTERISK_REGEX, 0) + 1
      } else if (prevChar === CLOSE_BRACKET && nextChar === OPEN_BRACKET) {
        caretPosition = textarea.current.selectionStart
      } else {
        caretPosition = updatedValue.length
      }

      updateCursorPosition(caretPosition)
    }
  }

  const applyHighlights = text => {
    return text
      ?.replace(/\n$/g, '\n\n')
      ?.replace(ATTRIBUTE_REGEX_OR_OPEN_BRACKETS, onReplaceAttribute)
  }

  const onReplaceAttribute = match => {
    const matchCopy = match?.replace(/{{/g, '')?.replace(/}}/g, '')
    const currentAttribute = attributes?.find(
      attribute => attribute.name === matchCopy,
    )
    const openBracketsStyle = `background: ${getAttributeColor(
      currentAttribute?.type,
    )}; color:transparent; border-radius: 50px 0 0 50px;`
    const attributeStyle = `background: ${getAttributeColor(
      currentAttribute?.type,
    )}; color:#ffffff;`
    const closeBracketsStyle = `background: ${getAttributeColor(
      currentAttribute?.type,
    )}; color:transparent; border-radius: 0 50px 50px 0;`

    return match.length > 2
    // eslint-disable-next-line max-len
      ? `<span style="${openBracketsStyle}">{{</span><span style="${attributeStyle}">${matchCopy}</span><span style="${closeBracketsStyle}">}}</span>`
      : `<span>${match}</span>`
  }

  const handleBlur = () => {
    setCaretPosition(textarea.current.selectionStart)
  }

  return (
    <div
      className={classes.container}
      style={{ height: height || '120px', padding: 0 }}>
      <div
        className={classes.backdrop}
        style={{
          height: height || '120px',
          borderRadius: disableBorderRadius && '0px',
        }}
        ref={backdrop}>
        <div
          className={classes.highlights}
          ref={highlights}
          dir={rtl && 'rtl'}
          style={{
            textAlign: rtl && 'right',
            unicodeBidi: rtl && 'bidi-override',
          }}
        />
      </div>
      <textarea
        className={classes.textarea}
        dir={rtl && 'rtl'}
        style={{
          height: height || '120px',
          borderRadius: disableBorderRadius && '0px',
          unicodeBidi: rtl && 'bidi-override',
        }}
        value={value}
        placeholder={placeholder}
        onChange={event => onChange(event.target.value)}
        onScroll={handleScroll}
        onInput={handleInput}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onClick={handleSkipAttribute}
        onBlur={handleBlur}
        ref={textarea}
      />
      <AttributesList
        addParam={addParam}
        show={show}
        onClose={handleCloseAttributeList}
        position={getPopupPosition()}
        showSearch={showSearch}
        searchValue={searchValue}
        listRef={attributeList}
        selectedAttribute={selectedAttribute}
        onKeyDown={event => onKeyDown(event)}
        updateSelectedAttribute={setSelectAttribute}
      />
      <span
        className={classes.icon}
        style={{ visibility: show && showSearch && 'visible' }}
        onClick={() => handleShowAttributes(true)}
        onKeyDown={event => onKeyDown(event)}
        tabIndex={'0'}
        ref={attributeIcon}>
        <AttributeIcon />
      </span>
    </div>
  )
}

InputWithParams.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
})

export default withStyles(styles)(connect(mapStateToProps)(InputWithParams))
