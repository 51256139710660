import React, { memo } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import Message from '../Message/Message'

import { styles } from './styles'
import { getUrlHash } from '../../../../helpers/getUrlHash'

const MessageGroup = props => {
  const { classes, messages, date, messageIntoView } = props
  const hash = getUrlHash()

  return (
    <div className={classes.container}>
      {messages &&
        messages
          .slice()
          .reverse()
          .filter(msg => msg.timestamp.split('T')[0] === date)
          .map(message => (
            <div
              ref={message.id === Number(hash) ? messageIntoView : null}
              key={message.id}>
              <Message msg={message} />
            </div>
          ))}
    </div>
  )
}

MessageGroup.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.array,
  date: PropTypes.string,
}

export default memo(withStyles(styles)(MessageGroup))
