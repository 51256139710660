import React from 'react'
import { connect } from 'react-redux'
import Loader from '../../../../uiKit/loaders/loader'
import DateControl from '../usersChart/DateControl'
import GridChart from './GridChart'
import GradientBar from './GradientBar'
import NoChartData from '../NoChartData'
import { loadPopularHoursStatistics } from '../../api/dashboard'
import { ByTimeOfDayHeaders } from '../../../../constants/byTimeOfDayHeaders'

const noDataText =
  'You have no conversations yet. They will appear here after your chatbot will get first users'
const tooltipText = `This chart shows the average amount of users per hour
        who were interacting with the bot for the selected period.`

const color = { r: 22, g: 88, b: 243 }
const gridItemsCount = 5

const convertDataCsv = data => {
  const { averageConversationByTimeOfDay } = data
  return averageConversationByTimeOfDay
}

const HourlyChart = ({
  botId,
  popularHoursStatistics: chartData,
  className,
}) => (
  <div className={className}>
    <DateControl
      custom
      style={!chartData ? { display: 'none' } : {}}
      botId={botId}
      loadData={loadPopularHoursStatistics}
      title="Average conversations by time of day"
      tooltipText={tooltipText}
      total={chartData ? chartData.total : 0}
      fileName={chartData ? 'by_time_of_headers' : ''}
      dataCsv={chartData ? convertDataCsv(chartData) : []}
      headersCsv={ByTimeOfDayHeaders}
    />
    {!chartData ? (
      <div
        style={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loader />
      </div>
    ) : chartData.averageConversationByTimeOfDay &&
      chartData.averageConversationByTimeOfDay.length ? (
        <div>
          <GridChart
            chartData={chartData}
            maxPerHour={chartData.maxPerHour}
            color={color}
            gridItemsCount={gridItemsCount}
          />
          <GradientBar
            gridItemsCount={gridItemsCount}
            color={color}
            step={chartData.maxPerHour / gridItemsCount}
          />
        </div>
      ) : (
        <NoChartData text={noDataText} />
      )}
  </div>
)

const mapStateToProps = state => ({
  popularHoursStatistics: state.popularHoursStatistics,
})

export default connect(mapStateToProps)(HourlyChart)
