import {
  SAVE_SUPPORT_USERS,
  SAVE_MORE_SUPPORT_USERS,
  SAVE_CLOSED_SUPPORT_USERS,
  SAVE_MORE_CLOSED_SUPPORT_USERS,
  UPDATE_SUPPORT_USER_LIST,
  UPDATE_USER_UNREAD_MESSAGE,
} from '../actions/users'

import { SUPPORT_STATUSES } from '../../../constants/suportStatuses'
import { getUrlEnd } from '../../../helpers/getUrlEnd'

const initialState = {
  assignedToMeUsers: [],
  newRequestsUsers: [],
  expiredRequestsUsers: [],
  assignedRequestsUsers: [],
  allUsers: [],
  closedRequestsUsers: [],
}

const _filterAssignedToMeUsers = payload => {
  return payload.supportUsers.filter(user => {
    return (
      user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
      user?.supportRequest?.support?.adminId === payload.adminUserId
    )
  })
}

const _filterNewRequestsUsers = payload => {
  return payload.supportUsers.filter(user => {
    return user?.supportRequest?.status === SUPPORT_STATUSES.OPEN
  })
}

const _filterExpiredRequestsUsers = payload => {
  return payload.supportUsers.filter(user => {
    return user?.supportRequest?.status === SUPPORT_STATUSES.EXPIRED
  })
}

const _filterAssignedRequestsUsers = payload => {
  return payload.supportUsers.filter(user => {
    return (
      user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
      user.supportRequest?.support?.adminId !== payload.adminUserId
    )
  })
}

const _filterAllUsers = payload => {
  return payload.supportUsers.filter(user => {
    return (
      user.supportRequest?.status !== SUPPORT_STATUSES.ASSIGNED &&
      user.supportRequest?.status !== SUPPORT_STATUSES.EXPIRED &&
      user.supportRequest?.status !== SUPPORT_STATUSES.OPEN
    )
  })
}

const _updateUserAssignedToMe = (state, payload) => {
  const rule =
    payload.user.supportRequest?.status !== SUPPORT_STATUSES.ASSIGNED ||
    payload.user.supportRequest?.support?.adminId !== payload.adminUserId

  return _updateUser(state, payload, rule)
}

const _updateUserExpired = (state, payload) => {
  const rule = payload.user.supportRequest?.status !== SUPPORT_STATUSES.EXPIRED

  return _updateUser(state, payload, rule)
}

const _updateUserAssigned = (state, payload) => {
  const rule =
    payload.user.supportRequest?.status !== SUPPORT_STATUSES.ASSIGNED ||
    (payload.user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
      payload.user.supportRequest?.support?.adminId === payload.adminUserId)

  return _updateUser(state, payload, rule)
}

const _updateUserNew = (state, payload) => {
  const rule = payload.user.supportRequest?.status !== SUPPORT_STATUSES.OPEN

  return _updateUser(state, payload, rule)
}

const _updateAllUser = (state, payload) => {
  const rule =
    payload.user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED ||
    payload.user.supportRequest?.status === SUPPORT_STATUSES.EXPIRED ||
    payload.user.supportRequest?.status === SUPPORT_STATUSES.OPEN

  return _updateUser(state, payload, rule)
}

const _updateUser = (state, payload, rule) => {
  const isNewUser = !state.find(user => user?.userId === payload.user?.userId)

  if (rule && isNewUser) return state

  if (rule && !isNewUser)
    return state.filter(user => user.userId !== payload.user.userId)

  return isNewUser
    ? [payload.user, ...state]
    : state.map(user => {
      if (user.userId === payload.user?.userId) {
        if (Number(getUrlEnd()) !== payload.user?.userId)
          payload.user.unreadMessages = user.unreadMessages + 1
        user = Object.assign({}, user, payload.user)
      }
      return user
    })
}

const _updateUserUnreadMessages = (state, payload) => {
  return state.map(user => {
    if (user?.userId === payload.user?.userId && user?.unreadMessage) {
      payload.user.unreadMessage = false
      payload.user.unreadMessages = 0
      payload.user.lastMessage = user.lastMessage
      return payload.user
    }
    return user
  })
}

const supportUsersNew = (state = initialState, { type, payload }) => {
  switch (type) {
  case SAVE_SUPPORT_USERS:
    return {
      ...state,
      assignedToMeUsers: _filterAssignedToMeUsers(payload),
      newRequestsUsers: _filterNewRequestsUsers(payload),
      expiredRequestsUsers: _filterExpiredRequestsUsers(payload),
      assignedRequestsUsers: _filterAssignedRequestsUsers(payload),
      allUsers: _filterAllUsers(payload),
    }

  case SAVE_MORE_SUPPORT_USERS:
    return {
      ...state,
      assignedToMeUsers: [
        ...state.assignedToMeUsers,
        ..._filterAssignedToMeUsers(payload),
      ],
      newRequestsUsers: [
        ...state.newRequestsUsers,
        ..._filterNewRequestsUsers(payload),
      ],
      expiredRequestsUsers: [
        ...state.expiredRequestsUsers,
        ..._filterExpiredRequestsUsers(payload),
      ],
      assignedRequestsUsers: [
        ...state.assignedRequestsUsers,
        ..._filterAssignedRequestsUsers(payload),
      ],
      allUsers: [...state.allUsers, ..._filterAllUsers(payload)],
    }

  case UPDATE_SUPPORT_USER_LIST:
    return {
      ...state,
      assignedToMeUsers: _updateUserAssignedToMe(
        state.assignedToMeUsers,
        payload,
      ),
      expiredRequestsUsers: _updateUserExpired(
        state.expiredRequestsUsers,
        payload,
      ),
      assignedRequestsUsers: _updateUserAssigned(
        state.assignedRequestsUsers,
        payload,
      ),
      newRequestsUsers: _updateUserNew(state.newRequestsUsers, payload),
      allUsers: _updateAllUser(state.allUsers, payload),
    }

  case UPDATE_USER_UNREAD_MESSAGE:
    return {
      ...state,
      assignedToMeUsers: _updateUserUnreadMessages(
        state.assignedToMeUsers,
        payload,
      ),
      expiredRequestsUsers: _updateUserUnreadMessages(
        state.expiredRequestsUsers,
        payload,
      ),
      assignedRequestsUsers: _updateUserUnreadMessages(
        state.assignedRequestsUsers,
        payload,
      ),
      allUsers: _updateUserUnreadMessages(state.allUsers, payload),
      newRequestsUsers: _updateUserUnreadMessages(
        state.newRequestsUsers,
        payload,
      ),
    }

  case SAVE_CLOSED_SUPPORT_USERS:
    return {
      ...state,
      closedRequestsUsers: payload.supportUsers,
    }

  case SAVE_MORE_CLOSED_SUPPORT_USERS:
    return {
      state,
      closedRequestsUsers: [
        ...state.closedRequestsUsers,
        ...payload.supportUsers,
      ],
    }

  default:
    return state
  }
}

export default supportUsersNew
