import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  inputHolder: {
    border: '1px solid #C7CAD6',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: '10px 24px',
    maxHeight: 45,
    width: 400,
    '&:focus': {
      outline: 0,
    },
  },
})

class InputNumber extends React.Component {
  handleChange(event) {
    const time = event.target.validity.valid
      ? event.target.value
      : this.props.value
    this.props.onChange(time)
  }

  render() {
    const { classes } = this.props
    return (
      <input
        className={classes.inputHolder}
        type="text"
        pattern="[0-9]*"
        onInput={this.handleChange.bind(this)}
        onKeyPress={e => {
          this.props.handleKeyPress(e)
        }}
        onBlur={e => {
          this.props.onBlur(e)
        }}
        value={this.props.value}
        min={0}
      />
    )
  }
}

InputNumber.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles)(InputNumber))
