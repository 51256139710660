import { saveFacebookPages } from '../actions/settingsPages'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { BASE_URL } from '../../../configs'

export function getFacebookPages(botId, accessToken, callback, token) {
  fetch(
    // eslint-disable-next-line max-len
    `https://graph.facebook.com/v3.1/me?access_token=${accessToken}&fields=accounts{id,access_token,name,picture{url},is_webhooks_subscribed}`,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(token),
      method: 'GET',
    },
  )
    .then(response => {
      if (response.ok)
        return response.redirected
          ? (window.location.href = response.url)
          : response.json()
    })
    .then(json => {
      saveFacebookPages(json.accounts.data)
    })
    .then(() => {
      if (callback) callback()
    })
    .catch(function(error) {
      alertError('Pages displaying error')
    })
}

export function exchangeShortLiveUserTokenToLongLive(accessToken) {
  return fetch(
    `${BASE_URL}/facebook/exchange/token?accessToken=${accessToken}`,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  ).then(response => {
    if (response.ok)
      return response.redirected
        ? (window.location.href = response.url)
        : response.json()
  })
}

export function subscribePage(
  botId,
  id,
  accessToken,
  pageName,
  callback,
  offLoader,
  token,
) {
  fetch(`${BASE_URL}/facebook/${botId}/subscribePage`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(token),
    method: 'POST',
    body: JSON.stringify({ id, accessToken, pageName }),
  }).then(response => {
    if (response.ok) callback(false)
    else if (response.status === 500) {
      response.json().then(text => alertError(text.message))
      offLoader()
    }
    return response.redirected
      ? (window.location.href = response.url)
      : response
  })
}

export function unsubscribePage(
  botId,
  id,
  accessToken,
  callback,
  offLoader,
  token,
) {
  fetch(`${BASE_URL}/facebook/${botId}/unsubscribePage`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(token),
    method: 'POST',
    body: JSON.stringify({ id, accessToken }),
  }).then(response => {
    if (response.ok) callback(true)
    else if (response.status === 500) {
      offLoader()
      response.json().then(text => alertError(text.message))
    }
    return response.redirected
      ? (window.location.href = response.url)
      : response
  })
}

export function getFacebookAppId() {
  return fetch(`${BASE_URL}/configs/facebook`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  }).then(response => logoutOnRequestOrResponseJson(response))
}
