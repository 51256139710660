import { deepCopyFunction } from './deepCopyFunction'
import { CONDITIONAL_REDIRECT_TYPE } from '../tabs/flowBuilder/constants/messageTypes'

export const formatAtom = atom => {
  const atomCopy = deepCopyFunction(atom)

  if (atomCopy) {
    atomCopy.messages = atomCopy?.messages?.map(message => {
      if (message.type === CONDITIONAL_REDIRECT_TYPE) {
        const { conditionalRedirect } = message

        message.conditionalRedirect =
          _formatConditionRedirect(conditionalRedirect)
      }
      return message
    })
  }

  return atomCopy
}

const _formatConditionRedirect = conditionalRedirect => {
  const { defaultRedirect, conditionGroups } = conditionalRedirect

  return {
    defaultRedirect,
    conditionGroups: conditionGroups?.map(_formatCondition),
  }
}

const _formatCondition = condition => {
  const { conjunction, redirectTo, priority, expressions } = condition

  return {
    conjunction,
    redirectTo,
    priority,
    expressions: expressions.map(_formatConditionExpression),
  }
}

const _formatConditionExpression = expression => {
  const { attribute, operator, value } = expression

  return {
    attribute,
    operator,
    value,
  }
}
