export const styles = theme => ({
  dashboard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 80px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: 28,
  },
  charts: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  chart: {
    padding: 40,
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    width: '49%',
    justifyContent: 'flex-start',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:not(:first-child)': {
      marginLeft: 40,
    },
    '&>div:not(.DateControl)': {
      minHeight: 'calc(100% - 80px - 70px)',
      marginTop: '40px',
    },
  },
  chartFull: {
    width: '100%',
  },
  chartsRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    margin: 'auto 0 40px',
  },
  '@global': {
    '.chartjs-size-monitor': {
      position: 'relative',
      maxHeight: '100%',
    },
    '.chartjs-render-monitor': {
      margin: '0 auto',
      maxWidth: '100%',
    },
  },
})
