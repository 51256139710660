import { BACKEND_URL } from '../../../configs'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'

export function getRatingDistribution(botId, fromDate, toDate) {
  const URL = `${BACKEND_URL}/bot/${botId}/analytics/feedback/ratingDistribution?from=${fromDate.format(
    'YYYY-MM-DD',
  )}&to=${toDate.format('YYYY-MM-DD')}`

  return fetch(URL, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(res => res)
    .catch(function() {
      alertError('Fail. Try it later')
    })
}

export function getComments(
  botId,
  fromDate,
  toDate,
  page,
  size,
  sortBy,
  sortDirection,
  score,
) {
  let URL = `${BACKEND_URL}/bot/${botId}/analytics/feedback/comments?from=${fromDate.format(
    'YYYY-MM-DD',
  )}&to=${toDate.format(
    'YYYY-MM-DD',
  )}&page=${page}&size=${size}&sortBy=${sortBy}&sortDirection=${sortDirection}`

  if (score !== null) URL = URL.addQueryStringParameter('score', score)

  return fetch(URL, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(res => res)
    .catch(function() {
      alertError('Fail. Try it later')
    })
}
