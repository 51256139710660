import { store } from '../../../index'

export const SET_BOT_WIDGET = 'SET_BOT_WIDGET'

export const setBotWidget = botWidget => {
  store.dispatch({
    type: SET_BOT_WIDGET,
    botWidget,
  })
}
