import React, { useState } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Tooltip from '../../../../uiKit/tooltip/Tooltip'
import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AttributeSelect from '../../../../uiKit/AttributeSelect/AttributeSelect'
import { PlusIcon } from '../../../../uiKit/icons/PlusIcon'
import SecondaryButton from '../../../../uiKit/SecondaryButton/SecondaryButton'

import { styles } from './styles'
import InputAutosuggest from '../../../../uiKit/InputAutosuggest/InputAutosuggest'
import { CHATBOT, DEFAULT } from '../../../../constants/attributeTypes'

const SetAttribute = props => {
  const { classes, message, updateMessage, onDelete } = props

  const [focus, setFocus] = useState(null)

  const handleUpdateAttribute = (e, index) => {
    message.attributes = deepCopyFunction(message?.attributes)?.map(
      (attribute, i) => {
        if (i === index) {
          attribute.id = e?.value
          attribute.name = e?.label
        }

        return attribute
      },
    )
    updateMessage(message)
    setFocus(false)
  }

  const handleUpdateAttributeValue = (e, index) => {
    message.attributes = deepCopyFunction(message?.attributes)?.map(
      (attribute, i) => {
        if (i === index) {
          attribute.value = e.target.value
        }
        return attribute
      },
    )

    updateMessage(message)
  }

  const handleAddAttribute = () => {
    const attributesUpdate = deepCopyFunction(message?.attributes) || []
    const newAttribute = { id: null, value: null, name: null }

    attributesUpdate.push(newAttribute)
    message.attributes = attributesUpdate
    updateMessage(message)
  }

  const handleDeleteAttribute = index => {
    message.attributes = deepCopyFunction(message?.attributes).filter(
      (attribute, i) => index !== i,
    )
    updateMessage(message)
  }

  return (
    <>
      <div className={classes.container}>
        {onDelete && (
          <i className={classes.deleteTemplate}>
            <IconButton
              className={classes.deleteIcon}
              aria-label="Delete"
              onClick={onDelete}>
              <TrashIcon width="20px" height="20px" color="#B0B2C0" />
            </IconButton>
          </i>
        )}

        <div className={classes.header}>
          <p className={classes.title}>Set attribute</p>
          <Tooltip
            tooltipId={'tooltip-attribute'}
            tooltipText={
              'Set a value for an existing chatbot attribute or add a new one'
            }
          />
        </div>

        <div className={classes.body}>
          <div className={classes.labels}>
            <p>Name</p>
            <p>Value</p>
          </div>
          {message?.attributes?.map((attribute, index) => (
            <div className={classes.attributeWrap} key={attribute?.id}>
              <AttributeSelect
                focus={focus === index || !attribute.id}
                value={attribute?.id}
                onChange={e => handleUpdateAttribute(e, index)}
                onBlur={() => setFocus(false)}
                onClick={() => setFocus(index)}
                required={true}
                displayOptions={[DEFAULT, CHATBOT]}
                containerStyle={{ width: '50%' }}
                isClearable={false}
                defaultAttributes={['language']}
              />
              <InputAutosuggest
                attributeId={attribute?.id}
                value={attribute?.value}
                placeholder={'none'}
                style={{ width: '50%' }}
                onChange={e => handleUpdateAttributeValue(e, index)}
              />
              {message?.attributes?.length > 1 && (
                <u
                  className={classes.iconWrap}
                  onClick={() => handleDeleteAttribute(index)}>
                  <TrashIcon />
                </u>
              )}
            </div>
          ))}
        </div>

        <div className={classes.footer}>
          <SecondaryButton
            title={
              <span>
                <PlusIcon /> Add attribute
              </span>
            }
            onClick={handleAddAttribute}
          />
        </div>
      </div>
    </>
  )
}

SetAttribute.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(SetAttribute)),
)
