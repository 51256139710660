import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  tableRow: {
    // "&:hover": {
    //     border: "0.5px solid #1658F3",
    //     borderRadius: 10,
    //     padding: 19,
    // },
    height: 80,
    padding: 20,
    fontSize: 14,
    display: 'flex',
    fontFamily: 'Lato, sans-serif',
    color: '#8185a6',
    justifyContent: 'space-between',
  },
})

class TableRow extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div
        style={
          (this.props.index + 1) % 2 === 1
            ? { borderRadius: 10 }
            : {
              background: 'rgba(90, 152, 247, 0.1)',
              borderRadius: 10,
            }
        }
        onClick={this.props.onClick}
        className={classes.tableRow}>
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(TableRow)
