import React from 'react'
import { connect } from 'react-redux'
import { PieChart } from 'react-chartkick'
import Loader from '../../../uiKit/loaders/loader'
import DateControl from './usersChart/DateControl'
import NoChartData from './NoChartData'
import { loadSupportStatistics } from '../api/dashboard'
import { covertHeaders } from '../../../constants/covertHeaders'

const colors = ['#1658F3', '#F3B116']
const noDataText =
  'You have no conversations yet. They will appear here after your chatbot will get first users'
const tooltipText =
// eslint-disable-next-line max-len
  'This chart shows the ratio of conversations covered only by the bot and conversations in which a user asked for human support.'

const convertDataCsv = data => {
  return [...[], data]
}

const CircleChart = ({ supportStatistics: chartData, botId, className }) => (
  <div className={className}>
    <DateControl
      title="Conversations covered"
      botId={botId}
      style={!chartData ? { display: 'none' } : {}}
      loadData={loadSupportStatistics}
      tooltipText={tooltipText}
      custom
      fileName={chartData ? 'conversations_covered' : ''}
      dataCsv={chartData ? convertDataCsv(chartData) : []}
      headersCsv={covertHeaders}
    />
    {CircleChartContent(chartData)}
  </div>
)

const CircleChartContent = chartData => {
  if (chartData) {
    const { byChatBot, byCustomerSupport } = chartData
    const data = [
      ['By chatbot', byChatBot],
      ['By customer support', byCustomerSupport],
    ]

    return byChatBot || byCustomerSupport ? (
      <PieChart data={data} colors={colors} />
    ) : (
      <NoChartData text={noDataText} />
    )
  } else {
    return <Loader />
  }
}

const mapStateToProps = state => ({
  supportStatistics: state.supportStatistics,
})

export default connect(mapStateToProps)(CircleChart)
