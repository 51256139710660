export const styles = () => ({
  tooltip: {
    maxWidth: '80%',
    width: '224px',
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
    textAlign: 'start',
    fontWeight: 'normal',
    padding: 10,
    backgroundColor: '#616581',
  },
  tooltipWrap: {
    cursor: 'pointer',
    display: 'inline',
    marginLeft: 4,
  },
})
