export const styles = {
  container: {
    padding: '12px 0px',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nameWrap: {
    fontSize: 14,
    fontWeight: 'bold',
    margin: 0,
    display: 'inline',
    '& span': {
      fontWeight: '300',
      color: 'rgba(53, 64, 82, 0.5)',
      marginLeft: 8,
    },
  },
  score: {
    color: '#FF624C',
  },
  comment: {
    paddingTop: 9,
    fontSize: 14,
    color: 'rgba(53, 64, 82, 0.7)',
  },
}
