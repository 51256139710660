import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'

import { getAttributeColor } from '../../helpers/getAttributeColor'
import AttributesModal from '../../tabs/settings/components/general/AttributesModal/AttributesModal'
import { FormatOption } from '../../helpers/formatOption'
import { styles, customStyles } from './styles'
import { createAttribute } from '../../tabs/settings/api/attributes'
import {
  CHATBOT,
  DEFAULT,
  FACEBOOK,
  TWILIO,
  WHATSAPP,
  WIDGET,
} from '../../constants/attributeTypes'
import ReactTooltip from 'react-tooltip'

const AttributeSelect = props => {
  const {
    classes,
    focus,
    value,
    onChange,
    onBlur,
    onClick,
    containerStyle,
    attributes,
    activeBot,
    required,
    styles,
    isClearable,
    placeholder,
    onFocus,
    displayOptions,
    twilioCredentials,
    defaultAttributes,
  } = props
  const [open, setOpen] = useState(false)
  const [touched, setTouched] = useState(false)
  const [isExtraWidth, setIsExtraWidth] = useState(false)
  const select = useRef(null)
  const attribute = useRef(null)
  const attributeContainer = useRef(null)
  const isFacebookConnected = activeBot?.messengerConfigs?.accessToken
  const isWhatsappConnected = activeBot?.amioWhatsAppConfigs?.accessToken
  const isTwilioConnected = twilioCredentials?.connected
  const attributeContainerPosition =
    attributeContainer?.current?.getBoundingClientRect()
  const menuPlacement =
    attributeContainerPosition?.top + 220 > window.innerHeight

  const options = [
    {
      label: 'Default attributes',
      options: FormatOption(attributes, DEFAULT, defaultAttributes),
      type: DEFAULT,
      connected: true,
    },
    {
      label: 'Chatbot attributes',
      options: FormatOption(attributes, CHATBOT),
      type: CHATBOT,
      connected: true,
    },
    {
      label: 'Widget attributes',
      options: FormatOption(attributes, WIDGET),
      type: WIDGET,
      connected: true,
    },
    {
      label: 'Facebook attributes',
      options: FormatOption(attributes, FACEBOOK),
      type: FACEBOOK,
      connected: isFacebookConnected,
    },
    {
      label: 'Whatsapp attributes',
      options: FormatOption(attributes, WHATSAPP),
      type: WHATSAPP,
      connected: isWhatsappConnected,
    },
    {
      label: 'Twilio attributes',
      options: FormatOption(attributes, TWILIO),
      type: TWILIO,
      connected: isTwilioConnected,
    },
  ].filter(option => displayOptions.includes(option.type) && option.connected)

  useEffect(() => {
    setIsExtraWidth(
      attribute?.current?.offsetWidth >=
        attributeContainer?.current?.offsetWidth,
    )
  }, [focus])

  useEffect(() => {
    setTouched(!!props.touched)
  }, [props.touched])

  const handleCreateAttribute = () => {
    const newAttribute = { name: select?.current?.state?.inputValue }

    createAttribute(activeBot.id, newAttribute).then(attribute => {
      const newAttribute = {
        value: attribute.id,
        label: attribute.name,
      }
      onChange(newAttribute)
    })
  }

  const currentValue = (() => {
    const currentAttribute = attributes?.find(
      attribute => attribute.id === value,
    )

    return {
      label: currentAttribute?.name,
      value: currentAttribute?.id,
      type: currentAttribute?.type,
    }
  })()

  const Menu = props => {
    return (
      <>
        <components.Menu {...props}>
          {props.children}
          <div className={classes.button} onClick={() => setOpen(true)}>
            Manage attibutes
          </div>
        </components.Menu>
      </>
    )
  }

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <div className={classes.addAttibute} onClick={handleCreateAttribute}>
          <p className={classes.optionTitle}>Create new attribute:</p>
          <p className={classes.option}>{select?.current?.state?.inputValue}</p>
        </div>
      </components.NoOptionsMessage>
    )
  }

  const Option = props => {
    return (
      <components.Option {...props}>
        <div
          className={classes.customOption}
          data-tip
          data-for={`${props.data.label}`}
          style={{ backgroundColor: getAttributeColor(props.data.type) }}>
          {props.children}
        </div>
        {props.data.description && (
          <ReactTooltip
            className={classes.tooltip}
            effect="solid"
            place="left"
            type="dark"
            id={`${props.data.label}`}>
            {props.data.description}
          </ReactTooltip>
        )}
      </components.Option>
    )
  }

  return (
    <>
      <div
        className={classes.attributeNameWrap}
        ref={attributeContainer}
        style={{ ...containerStyle }}>
        {focus ? (
          <div className={classes.inputWrap}>
            <Select
              placeholder={placeholder || 'Attribute name'}
              styles={customStyles(required, styles)}
              value={currentValue?.value ? currentValue : ''}
              onChange={onChange}
              onBlur={() => onBlur && onBlur()}
              autoFocus={value}
              openMenuOnFocus={true}
              options={options}
              components={{ Menu, NoOptionsMessage, Option }}
              menuPlacement={menuPlacement ? 'top' : 'auto'}
              touched={touched}
              isSearchable={true}
              onMenuClose={() => setTouched(true)}
              ref={select}
              onFocus={() => onFocus && onFocus()}
              isClearable={isClearable}
            />
          </div>
        ) : (
          <p
            className={classes.attributeName}
            onClick={onClick}
            data-tip={currentValue.label}
            data-for={currentValue.label}
            ref={attribute}
            style={{ backgroundColor: getAttributeColor(currentValue.type) }}>
            {currentValue.label}
            {isExtraWidth && (
              <ReactTooltip
                className={classes.tooltip}
                effect="solid"
                place="top"
                type="dark"
                id={currentValue.label}
              />
            )}
          </p>
        )}
      </div>

      <AttributesModal open={open} onClose={() => setOpen(false)} />
    </>
  )
}

AttributeSelect.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBot: state.activeBot,
  twilioCredentials: state.twilioCredentials,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(AttributeSelect)),
)
