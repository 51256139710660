export const styles = () => ({
  input: {
    outline: 'none',
    width: '100%',
    height: 38,
    borderRadius: 4,
    fontSize: 14,
    padding: '8px 12px',
    border: '1px solid #d7d8df',
    '&:hover': {
      border: '1px solid rgba(53, 64, 82, 0.5)',
    },
    '&::placeholder': {
      color: '#999',
    },
  },
  item: {
    color: '#354052',
    padding: '8px 12px',
  },
  menu: {
    border: '1px solid rgba(53, 64, 82, 0.1)',
    background: 'rgba(255, 255, 255, 0.9)',
    fontSize: '90%',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: 1000,
    cursor: 'pointer',
    borderRadius: 4,
    width: '100%',
  },
  container: {
    position: 'relative',
  },
})
