import React from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { styles } from './AnalyticsHeaderStyles'
import DateRangePicker from 'react-bootstrap-daterangepicker'

const AnalyticsHeader = props => {
  const { classes, startDate, endDate, ratingDistribution, setDate } = props
  const inputValue = `${startDate.format('YYYY-MM-DD')} - ${endDate.format(
    'YYYY-MM-DD',
  )}`
  const score = Math.round(ratingDistribution.averageScore * 10) / 10

  const saveChanges = (event, picker) => {
    setDate(picker.startDate, picker.endDate)
  }

  return (
    <div className={classes.header}>
      <p className={classes.title}>Ratings and Reviews</p>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onApply={saveChanges}>
        <input type="text" name="daterange" value={inputValue} />
      </DateRangePicker>
      <div className={classes.rateContainer}>
        <p className={classes.rateWrap}>
          Average rating
          <span> {score}/5 </span>
          <span style={{ color: '#FF624C' }}>★ </span>
        </p>
        <p className={classes.rateWrap}>
          Ratings
          <span> {ratingDistribution.totalFeedbacks}</span>
        </p>
      </div>
    </div>
  )
}

AnalyticsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  ratingDistribution: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles)(AnalyticsHeader))
