import React, { useState } from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './ChartStyles'
import { initialColors, initialDataSet, chartOptions } from './ChartConfig'

const Chart = props => {
  const { data, classes, handleSelectedBar, selectedBar } = props
  const [dataSet, setDataSet] = useState(Object.assign({}, initialDataSet))
  const options = chartOptions

  dataSet.datasets[0].data = Object.values(data).reverse()

  options.onClick = function(evt, element) {
    if (element.length > 0) {
      const copyDataSet = Object.assign({}, dataSet)
      const formedIndex = Math.abs(5 - element[0]._index)

      copyDataSet.datasets[0].backgroundColor = [...initialColors]
      copyDataSet.datasets[0].hoverBackgroundColor = [...initialColors]

      if (selectedBar === formedIndex) {
        copyDataSet.datasets[0].backgroundColor[element[0]._index] = '#9bb3e4'
        copyDataSet.datasets[0].hoverBackgroundColor[element[0]._index] =
          '#9bb3e4'
        handleSelectedBar(null)
      } else {
        copyDataSet.datasets[0].backgroundColor[element[0]._index] = '#547ed0'
        copyDataSet.datasets[0].hoverBackgroundColor[element[0]._index] =
          '#547ed0'
        handleSelectedBar(formedIndex)
      }

      setDataSet(copyDataSet)
    }
  }

  return (
    <div className={classes.container}>
      <HorizontalBar data={dataSet} options={options} />
    </div>
  )
}

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleSelectedBar: PropTypes.func,
  selectedBar: PropTypes.number,
}

export default withStyles(styles)(Chart)
