/**
 * Created by Admin on 08.02.2018.
 */
import React, { Component } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { getAllPagingIntents } from '../api/nlp'
import { withRouter } from 'react-router-dom'

const styles = theme => ({})

class IntentsSelect extends Component {
  state = {
    states: [],
  }

  handleChangeState(value) {
    this.setState({
      selectedOption: value,
    })
    this.props.payload(value.value)
  }

  componentWillMount() {
    const intents = []
    this.props.intents &&
      this.props.intents.forEach(intent => {
        intents.push({
          label: intent.name,
          value: intent.name,
        })
      })
    if (this.props.value && this.props.value.includes('smalltalk.')) {
      intents.push({
        label: this.props.value,
        value: this.props.value,
      })
    }
    this.setState({
      intents,
      selectedOption: this.props.value
        ? { value: this.props.value, label: this.props.value }
        : undefined,
    })
  }

  componentWillReceiveProps(nextProps) {
    const intents = []
    nextProps.intents &&
      nextProps.intents.forEach(intent => {
        intents.push({
          label: intent.name,
          value: intent.name,
        })
      })
    if (this.props.value && this.props.value.includes('smalltalk.')) {
      intents.push({
        label: this.props.value,
        value: this.props.value,
      })
    }
    this.setState({
      intents,
      selectedOption: nextProps.value
        ? { value: nextProps.value, label: nextProps.value }
        : undefined,
    })
  }

  handleInputChange(value, action) {
    if (action.action === 'input-change') {
      getAllPagingIntents(this.props.match.params.botId, value)
    }
  }

  render() {
    const addDisabled = element => {
      element.disabled = element.label.includes('smalltalk.')
      return element
    }

    const intentsModified = this.state.intents.map(addDisabled)
    return (
      <div style={{ width: '100%' }}>
        <Select
          id="state-select"
          ref={ref => {
            this.select = ref
          }}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          options={intentsModified}
          className={this.state.selectedOption == undefined ? 'error' : ''}
          simpleValue
          style={{
            borderRadius: 10,
            fontFamily: 'Lato, sans-serif',
            height: 45,
            border:
              this.state.selectedOption == undefined
                ? '1px solid #FF624C'
                : '1px solid #ccc',
          }}
          value={this.state.selectedOption}
          name="selected-state"
          onChange={value => this.handleChangeState(value)}
          searchable={true}
          onInputChange={(value, action) =>
            this.handleInputChange(value, action)
          }
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  intents: state.intents,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(IntentsSelect)),
)
