import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import NavLink from 'react-router-dom/NavLink'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { HOME_PATH } from '../../../../configs'
import { PLATFORM_IMAGE, FACELESS_PICTURE, SUPPORT_STATUSES } from './config'
import { saveActiveUser } from '../../actions/activeUser'

import Badge from '../../../../uiKit/Badge/Badge'
import { styles } from './styles'
import UserNameBlock from '../UserNameBlock/UserNameBlock'

const UserDialogPreview = props => {
  const { classes, match, adminUser, activeUser, user } = props

  const isAssigned = user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED

  const isAssignedToCurrentAdmin =
    user?.supportRequest?.support?.adminId === adminUser?.id &&
    user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED

  const isUnread =
    user?.unreadMessages && activeUser?.userId !== user?.userId ? 'unread' : ''

  const boldLastMessage = isUnread || (isAssigned && !isAssignedToCurrentAdmin)

  const getLastMessage = user => {
    if (user?.lastMessage) {
      const text = JSON.parse(user?.lastMessage).text
      const title = JSON.parse(user?.lastMessage).title

      return text || title || 'Attachment'
    }
    if (user?.postback) {
      const title = JSON.parse(user?.postback).title
      return title || JSON.parse(user?.postback).payload
    }
    return 'Attachment'
  }

  const lastMsgText =
    isAssigned && !isAssignedToCurrentAdmin
      ? `Live chat with ${user?.supportRequest?.support?.name}`
      : getLastMessage(user)

  const handleClickUserPreview = () => {
    saveActiveUser(user)
  }

  return (
    <NavLink
      to={`${HOME_PATH}/bot/${match.params.botId}/support/${user?.userId}`}
      activeClassName={classes.activeLink}>
      <div className={classes.container} onClick={handleClickUserPreview}>
        <div
          className={classes.profileImage}
          style={{
            backgroundImage: `url(${user?.profilePic}), url(${FACELESS_PICTURE})`,
          }}>
          <img
            src={PLATFORM_IMAGE[user?.platform]}
            alt="platform icon"
            className={classes.channelIcon}
          />
        </div>

        <div className={classes.userInfo}>
          <UserNameBlock
            user={user}
            isAssigned={isAssigned}
            isAssignedToCurrentAdmin={isAssignedToCurrentAdmin}
          />

          <div className={classes.lastMsgTextWrap}>
            <p
              className={
                !boldLastMessage
                  ? classes.lastMsgText
                  : classes.lastMsgTextUnread
              }>
              {lastMsgText}
            </p>
            {isUnread && <Badge badgeContent={user?.unreadMessages} />}
          </div>
        </div>
      </div>
    </NavLink>
  )
}

UserDialogPreview.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  adminUser: state.adminUser,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(UserDialogPreview)),
)
