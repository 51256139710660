import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { BASE_URL } from '../../../configs'
import { savePopularHoursStatistics } from '../actions/popularHoursStatistics'
import { saveSupportStatistics } from '../actions/supportStatistics'
import { saveUsersStatistics } from '../actions/usersStatistics'

export const loadUsersStatistics = (
  botId,
  startDate,
  endDate,
  hourly,
  callback,
) => {
  const loadDataUrl = !hourly
    ? `${BASE_URL}/chart/users/${botId}?from=${startDate}&to=${endDate}`
    : `${BASE_URL}/chart/users/${botId}/last24hours`

  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'same-origin',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveUsersStatistics(responseJson)
      callback()
    })
    .catch(err => {
      alertError('Not able to load user statistics data!')
    })
}

export const loadSupportStatistics = (botId, startDate, endDate) => {
  fetch(
    `${BASE_URL}/chart/conversationCovering/${botId}?from=${startDate}&to=${endDate}`,
    {
      method: 'GET',
      headers: createJsonHeaders(),
      credentials: 'same-origin',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveSupportStatistics(responseJson)
    })
    .catch(() => {
      alertError('Not able to load chatbot/support statistics data!')
    })
}

export const loadPopularHoursStatistics = (botId, startDate, endDate) => {
  fetch(
    `${BASE_URL}/chart/hourlyConversation/${botId}?from=${startDate}&to=${endDate}`,
    {
      method: 'GET',
      headers: createJsonHeaders(),
      credentials: 'same-origin',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      savePopularHoursStatistics(responseJson)
    })
    .catch(() => {
      alertError('Not able to load popular hours statistics data!')
    })
}
