import { SET_BOT_WIDGET } from '../actions/botWidget'

const botWidget = (state = [], action) => {
  if (action.type === SET_BOT_WIDGET) {
    return action.botWidget
  } else {
    return state
  }
}

export default botWidget
