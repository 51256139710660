export const styles = () => ({
    votesWrap: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    votesContainer: {
        marginTop: 24,
        color: 'rgba(53, 64, 82)',
        fontSize: 14,
        padding:'0px 16px'
    },
    noVotes: {
        width: '100%',
        height: 4,
        backgroundColor: '#cccccc',
        borderRadius: 6
    },
    votesLineUp: {
        height: 4,
        backgroundColor: '#5FC2A2',
        borderRadius: '6px 0px 0px 6px'
    },
    votesLineDown: {
        height: 4,
        backgroundColor: '#FF6666',
        borderRadius: '0px 6px 6px 0px'
    },
    votesWrapLine: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 8
    }
});


export const getWidth = (width) => {
    return {
        width: `${width}%`,
        borderRadius: width === 100 && 6
    }
};
