/**
 * Created by Admin on 22.03.2018.
 */
/**
 * @author Den Boyko
 * @version 1.0
 */
import React from 'react'
import Dropzone from 'react-dropzone'
import { withStyles } from '@material-ui/core/styles'
import { alertError } from '../../../api'
import { PhotoIcon } from '../../../uiKit/icons/Icons'
import { handleDrop } from '../api/widget'
import { isStringEmpty } from '../../../helpers/isStringEmpty'

const styles = theme => ({
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
  replace: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  svgWrap: {
    width: 40,
    height: 40,
    background: 'rgba(215, 216, 223, 0.5)',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    background: '#FFFFFF',
    border: '1px dashed #D7D8DF',
    boxSizing: 'border-box',
    borderRadius: 10,
    width: '320px',
    maxWidth: 650,
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 0',
  },
  upload: {
    color: theme.palette.primary.main,
  },
  containerEmpty: {
    padding: '47px 0 25px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  header: {
    margin: '16px 0 2px',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.text.header,
  },
  description: {
    margin: 0,
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.text.gray,
  },
})
class DropzoneWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  handleDrop = files => {
    if (files[0].size > 1048576) {
      alertError('File size should be less then 1 MB.', 10000)
    } else {
      const file = new FormData()
      file.append('file', files[0])
      handleDrop(file, response => this.props.onChange(response))
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div
        className={
          isStringEmpty(this.props.value)
            ? classes.container
            : classes.containerEmpty
        }>
        <Dropzone
          onDrop={this.handleDrop}
          style={{
            width: !isStringEmpty(this.props.value) ? '100px' : '60%',
            height: !isStringEmpty(this.props.value) ? '100px' : 'auto',
            margin: '0',
            border: !isStringEmpty(this.props.value)
              ? '1px solid #e5e5e5'
              : '0',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}>
          <img
            src={this.props.value}
            alt="file"
            className={classes.image}
            style={
              this.props.value && !this.state.show ? {} : { display: 'none' }
            }
          />
          {isStringEmpty(this.props.value) && (
            <div className={classes.replace}>
              <div style={{ display: 'block' }}>
                <div className={classes.svgWrap}>
                  <PhotoIcon height="15px" width="19px" />
                </div>
              </div>

              <p className={classes.header}>
                Drag and drop or
                <span className={classes.upload}>Upload</span>
              </p>
              <p className={classes.description}>a 40x40px .jpeg or .png</p>
            </div>
          )}
        </Dropzone>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(DropzoneWidget)
