export const container = {
  width: 200,
  height: 314,
}

export const ATTRIBUTE_REGEX = /{{[^{]*?}}/g
export const ATTRIBUTE_REGEX_OR_OPEN_BRACKETS = /{{[^{]*?}}|{{/g
export const ATTRIBUTE_REGEX_INCLUDE = /({{[^{]*?}})/g
export const NOT_ASTERISK_REGEX = '[^*]+'
export const EMPTY_BRACKETS = ''
export const OPEN_BRACKET = '{'
export const CLOSE_BRACKET = '}'
export const ASTERISK = '*'
export const CLOSE_PAIR_BRACKET = '}}'
export const OPEN_PAIR_BRACKET = '{{'
