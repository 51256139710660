/* eslint-disable */
import React from 'react'

export const WhatAppIcon = () => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none">
      <path
        d="M0 23.1445L1.62521 17.203C0.625924 15.4645 0.0933396 13.4895 0.0988301 11.4705C0.0988301 5.14384 5.238 0 11.5522 0C14.6159 0 17.4929 1.19381 19.6507 3.36138C21.814 5.52894 23.0055 8.4117 23 11.476C23 17.8026 17.8608 22.9465 11.5467 22.9465H11.5412C9.62496 22.9465 7.7417 22.4624 6.06708 21.5491L0 23.1445ZM6.35259 19.4696L6.69849 19.6786C8.15898 20.5479 9.8336 21.0045 11.5412 21.01H11.5467C16.7901 21.01 21.0618 16.7354 21.0618 11.476C21.0618 8.92883 20.0735 6.53571 18.2781 4.73124C16.4827 2.92676 14.0888 1.93651 11.5467 1.93651C6.29768 1.93651 2.02602 6.21112 2.02602 11.4705C2.02602 13.2695 2.52566 15.0244 3.48102 16.5428L3.70613 16.9059L2.75077 20.4158L6.35259 19.4696Z"
        fill="white"
      />
      <path
        d="M0.395508 22.7414L1.96581 17.0034C0.99947 15.3254 0.488847 13.4164 0.488847 11.4689C0.488847 5.36233 5.45232 0.394531 11.5414 0.394531C14.5008 0.394531 17.2735 1.54983 19.3599 3.64038C21.4463 5.73092 22.5939 8.51465 22.5939 11.4744C22.5939 17.581 17.6304 22.5488 11.5414 22.5488H11.5359C9.68554 22.5488 7.86817 22.0812 6.25394 21.201L0.395508 22.7414Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M0 23.1445L1.62521 17.203C0.625924 15.4645 0.0933396 13.4895 0.0988301 11.4705C0.0988301 5.14384 5.238 0 11.5522 0C14.6159 0 17.4929 1.19381 19.6507 3.36138C21.814 5.52894 23.0055 8.4117 23 11.476C23 17.8026 17.8608 22.9465 11.5467 22.9465H11.5412C9.62496 22.9465 7.7417 22.4624 6.06708 21.5491L0 23.1445ZM6.35259 19.4696L6.69849 19.6786C8.15898 20.5479 9.8336 21.0045 11.5412 21.01H11.5467C16.7901 21.01 21.0618 16.7354 21.0618 11.476C21.0618 8.92883 20.0735 6.53571 18.2781 4.73124C16.4827 2.92676 14.0888 1.93651 11.5467 1.93651C6.29768 1.93651 2.02602 6.21112 2.02602 11.4705C2.02602 13.2695 2.52566 15.0244 3.48102 16.5428L3.70613 16.9059L2.75077 20.4158L6.35259 19.4696Z"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68635 6.6686C8.47221 6.18997 8.2471 6.17897 8.04395 6.17347C7.87923 6.16797 7.68706 6.16797 7.49489 6.16797C7.30272 6.16797 6.99525 6.23949 6.7317 6.52556C6.46816 6.81164 5.73242 7.50482 5.73242 8.91869C5.73242 10.3271 6.75916 11.6914 6.90191 11.884C7.04467 12.0765 8.88401 15.0638 11.7885 16.2136C14.2044 17.1708 14.6985 16.9783 15.2201 16.9288C15.7472 16.8793 16.9112 16.2356 17.1528 15.5644C17.3889 14.8932 17.3889 14.3211 17.3175 14.2001C17.2461 14.079 17.054 14.0075 16.7685 13.8645C16.4829 13.7214 15.0774 13.0283 14.8138 12.9292C14.5503 12.8357 14.3581 12.7862 14.1714 13.0723C13.9792 13.3583 13.4302 14.002 13.2655 14.1946C13.1008 14.3871 12.9306 14.4091 12.645 14.2661C12.3595 14.123 11.4371 13.8205 10.3445 12.8412C9.49346 12.082 8.91695 11.1413 8.75223 10.8552C8.58752 10.5691 8.73576 10.4151 8.87852 10.272C9.0048 10.1455 9.16402 9.93645 9.30678 9.77141C9.44953 9.60637 9.49895 9.48534 9.59229 9.29279C9.68563 9.10023 9.6417 8.93519 9.57033 8.79215C9.49346 8.65462 8.93891 7.23525 8.68635 6.6686Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="11.4983"
          y1="22.7409"
          x2="11.4983"
          y2="0.394443"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="11.4982"
          y1="23.143"
          x2="11.4982"
          y2="-0.00481384"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}
