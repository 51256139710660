export const FACELESS_PICTURE =
  'https://nrm.co.nz/wp-content/uploads/2017/08/facebook-avatar.jpg'

export const SUPPORT_ICON = '/images/platform/support-icon.svg'

export const MY_SUPPORT_ICON = '/images/platform/my-support-icon.svg'

export const EXPIRED_ICON = '/images/platform/time-expired.svg'

export const PLATFORM_IMAGE = {
  WEBSITE: '/images/platform/widget-icon.svg',
  FB_MESSENGER: '/images/platform/messenger-icon.svg',
  AMIO_WHATSAPP: '/images/platform/whatsapp-icon.svg',
}

export const SUPPORT_STATUSES = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
}
