import { SET_ANALYTICS } from '../actions'

const analytics = (state = null, action) => {
  if (action.type === SET_ANALYTICS) {
    return action.analytics
  } else {
    return state
  }
}

export default analytics
