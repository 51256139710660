/**
 * Created by Admin on 25.04.2018.
 */
import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { LinkIcon } from '../../../../uiKit/icons/Icons'
import TabTemplate from '../TabTemplate'
import Urls from '../Urls'
import { alertSuccess } from '../../../../api'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { downloadWidget } from '../../api/widget'
import { BACKEND_URL } from '../../../../configs'

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: theme.palette.text.header,
  },
  color: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header,
  },
  description: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.primary.text,
    marginTop: 16,
  },
  label: {
    fontFamily: 'Lato',
    fontSize: 14,
    marginLeft: -16,
    display: 'block',
    paddingTop: 16,
    color: theme.palette.primary.text,
  },
  save: {
    padding: '12px 35px',
    border: 0,
    background: '#1658F3',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    borderRadius: 10,
    cursor: 'pointer',
    color: 'white',
    margin: '30px auto 0',
    display: 'block',
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: '400px',
    borderRadius: '10px',
    display: 'inline-block',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: '8px 10px',
    textOverflow: 'ellipsis',
    maxHeight: 43,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rootCheckbox: {
    marginBottom: 0,
    color: theme.palette.primary.iconsGray,
    fontSize: 14,
    fontFamily: 'Lato',
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  tooltip: {
    maxWidth: '80%',
    width: '400px',
    border: '1px solid #EBEBEF',
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
    cursor: 'pointer',
  },
  zipText: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.primary.text,
    margin: '12px 0 16px',
  },
  '@global': {
    'label.checkbox ': {
      marginBottom: -12,
    },
  },
})

class WidgetConnection extends React.Component {
  handleWidgetDownload = () => {
    downloadWidget(this.props.match.params.botId)
  }

  handleChange = (value, name) => {
    const widget = JSON.parse(JSON.stringify(this.props.widget))
    if (widget[name] === value) return
    widget[name] = value
    this.props.handleChange(value, name)
  }

  render() {
    const { classes, activeBot } = this.props
    const { scriptChatbotUrl, websiteUrl } = this.props.widget

    // eslint-disable-next-line max-len
    const scriptSnipped = `<script type="text/javascript" id="chatbot-initials-script" src="${scriptChatbotUrl}" server-url="${BACKEND_URL}" bot-id="${activeBot.id}"></script>`

    return (
      <div>
        <TabTemplate
          tab={{
            name: 'Integration',
            icon: <LinkIcon />,
            description: 'Connect your chatbot to the website you own',
          }}
          expanded={this.props.expanded}
          onChange={this.props.handleChangeExpansion}>
          {websiteUrl !== undefined && (
            <div>
              <p className={classes.title}>Provide your website URL</p>
              <Urls
                handleChange={value => {
                  this.handleChange(value, 'websiteUrl')
                }}
                websiteUrl={websiteUrl}
              />
              <div className={classes.inputHolder}>{scriptSnipped}</div>
              <ReactTooltip
                className={classes.tooltip}
                place="bottom"
                type="light"
                effect="solid"
                id="tooltip-1">
                Copy widget code snippet to clipboard and paste it in your
                website source code before closing
                <span style={{ background: '#DEEAFD', marginLeft: 4 }}>
                  &lt;/body&gt;
                </span>{' '}
                tag.
              </ReactTooltip>

              <div
                style={{
                  display: 'flex',
                  width: 400,
                  justifyContent: 'flex-start',
                }}>
                <CopyToClipboard
                  text={scriptSnipped}
                  onCopy={() => this.setState({ copied: true })}>
                  <SubmitButton
                    title="Copy to clipboard"
                    onClick={() => {
                      alertSuccess('Copied!')
                    }}
                  />
                </CopyToClipboard>
              </div>
            </div>
          )}
        </TabTemplate>
      </div>
    )
  }
}

WidgetConnection.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(WidgetConnection),
  ),
)
