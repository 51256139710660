import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'

const SecondaryButton = props => {
  const { classes, onClick, styles, title } = props

  return (
    <div
      className={classes.button}
      onClick={e => onClick && onClick(e)}
      style={styles}>
      {title}
    </div>
  )
}

SecondaryButton.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(SecondaryButton)
