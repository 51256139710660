import React from 'react'
import PropTypes from 'prop-types'
import ReactAudioPlayer from 'react-audio-player'

import MessageWrap from '../MessageWrap/MessageWrap'

const AudioMessage = props => {
  const { timezone, isReceived, url } = props

  return (
    <MessageWrap timezone={timezone} isReceived={isReceived}>
      <ReactAudioPlayer src={url} controls />
    </MessageWrap>
  )
}

AudioMessage.propTypes = {
  classes: PropTypes.object,
  isReceived: PropTypes.bool,
  url: PropTypes.string,
}

export default AudioMessage
