import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {
  BrowserRouter,
  NavLink,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'

import { getBot } from '../../tabs/home/api/bots'
import { HOME_PATH, BACKEND_URL } from '../../configs'
import { getSupportRequests } from '../../tabs/support/api/support'
import { hasPermissionForBot } from '../../security'
import { mainRoutes } from '../../routes/mainRoutes'
import PermissionDenied from '../404/404'
import Loader from '../../uiKit/loaders/loader'
import WebSockets from '../../contexts/webSockets'
import { clearOldBot } from '../../tabs/home/actions/activeBot'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { UserMenu } from '../../tabs/account/components/UserMenu'
import { clearAttributes } from '../../tabs/settings/actions/attributes'

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    marginTop: '0',
    height: '100vh',
    background: theme.palette.primary.background,
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  flex: {
    flex: 1,
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'none',
    boxShadow: 'none',
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  Toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px 0 116px',
    zIndex: 0,
    background: '#FFFFFF',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    fontFamily: 'Lato, sans-serif',
    ...theme.mixins.toolbar,
  },
  content: {
    width: 800,
    flexGrow: 1,
    backgroundColor: theme.palette.primary.background,
    paddingLeft: 40,
    // paddingRight: 12,
    marginTop: 65,
    overflowY: 'auto',
  },
  icon: {
    color: 'grey',
  },
  list: {
    width: 100,
    overflowY: 'auto',
    height: '100%',
    paddingTop: 64,
  },
  item: {
    paddingLeft: 16,
  },
  supportAmount: {
    top: 8,
    right: 30,
    borderRadius: 100,
    backgroundColor: theme.palette.alerts.error,
    fontSize: '12px',
    height: 20,
    width: 20,
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  menuWrap: {
    background: 'linear-gradient(359.25deg, #5A98F7 49.98%, #1658F3 149.98%)',
    boxShadow: '0px 2px 23px rgba(0, 0, 0, 0.0593014)',
    zIndex: 9999,
  },
  listItem: {
    color: 'white',
    marginTop: 8,
    marginBottom: 8,
    fontSize: 12,
  },
  title: {
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontSize: 16,
    color: '#3A3F62',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 75,
    paddingTop: 10,
    position: 'relative',
    '&:hover': {
      background: 'rgba(19,69,186,0.5)',
      transition: '0.3s all ease-in',
      '& svg path': {
        stroke: '#ffffff',
        background: 'rgba(19,69,186,0.5)',
        transition: '0.3s all ease-in',
      },
    },
  },
  activeLink: {
    background: 'rgba(19,69,186,0.5)',
    '& svg path': {
      stroke: '#ffffff',
    },
  },
})

class MainMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      redirectToBots: false,
      activeBot: null,
      ifCreateSocket: false,
      redirectToAccountSettings: false,
    }
  }

  componentDidMount() {
    getBot(this.props.match.params.botId)
    getSupportRequests(this.props.match.params.botId)
  }

  componentWillUnmount() {
    clearOldBot()
    clearAttributes()
  }

  redirectToBots() {
    this.setState({ redirectToBots: true })
  }

  redirectToAccountSettings = () => {
    this.setState({ redirectToAccountSettings: true })
  }

  render() {
    if (this.state.redirectToBots) {
      return <Redirect to={HOME_PATH} />
    }

    if (this.state.redirectToAccountSettings) {
      return (
        <Redirect
          to={{
            pathname: '/account-settings',
            state: { from: this.props.location.pathname },
          }}
        />
      )
    }

    const { classes, activeBot, supportRequests } = this.props
    const pathLink = `${HOME_PATH}/bot/${this.props.match.params.botId}/`
    const pathRoute = `${HOME_PATH}/bot/:botId/`

    return (
      <div className={classes.root}>
        {this.props.adminUser ? (
          <BrowserRouter>
            <WebSockets
              adminUser={this.props.adminUser}
              botId={this.props.match.params.botId}>
              <div className={classes.appFrame}>
                <AppBar position="absolute" className={classes.appBar}>
                  <Toolbar
                    className={classes.Toolbar}
                    disableGutters={!this.state.open}>
                    <p
                      onClick={() => this.redirectToBots()}
                      style={{
                        fontFamily: 'Lato, sans-serif',
                        padding: '0',
                        margin: '0 24px 0 0',
                        cursor: 'pointer',
                        fontWeight: 400,
                        color: '#1658F3',
                      }}>
                      Home
                    </p>
                    {this.props.activeBot && (
                      <Typography
                        type="title"
                        style={{ fontFamily: 'Lato, sans-serif' }}
                        className={classes.title}>
                        {this.props.activeBot.name}
                      </Typography>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {activeBot &&
                        activeBot.messengerConfigs &&
                        activeBot.messengerConfigs.pageId && (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={`https://www.messenger.com/t/${this.props.activeBot.messengerConfigs.pageId}`}
                          target="_blank" rel="noreferrer">
                          <Button
                            style={{
                              background:
                                  'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
                              boxShadow:
                                  '0px 3px 9px rgba(19, 69, 186, 0.206267)',
                              borderRadius: 10,
                              height: 45,
                            }}>
                            <p
                              style={{
                                color: 'white',
                                margin: 0,
                                fontWeight: 500,
                                fontSize: 16,
                                textTransform: 'none',
                                fontFamily: 'Lato, sans-serif',
                              }}>
                                Test in Messenger
                            </p>
                          </Button>
                        </a>
                      )}
                      {activeBot && (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={`${BACKEND_URL}/test-widget/${this.props.match.params.botId}`}
                          target="_blank" rel="noreferrer">
                          <Button
                            style={{
                              background:
                                'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
                              boxShadow:
                                '0px 3px 9px rgba(19, 69, 186, 0.206267)',
                              borderRadius: 10,
                              height: 45,
                              margin: '0 30px',
                            }}>
                            <p
                              style={{
                                color: 'white',
                                margin: 0,
                                fontWeight: 500,
                                fontSize: 16,
                                textTransform: 'none',
                                fontFamily: 'Lato, sans-serif',
                              }}>
                              Test widget
                            </p>
                          </Button>
                        </a>
                      )}

                      <UserMenu
                        redirectToAccountSettings={
                          this.redirectToAccountSettings
                        }
                        botId={this.props.match.params.botId}
                      />
                    </div>
                  </Toolbar>
                </AppBar>
                <div className={classes.menuWrap}>
                  <List className={classes.list}>
                    {mainRoutes.map(
                      route =>
                        hasPermissionForBot(
                          this.props.match.params.botId,
                          route.path,
                        ) && (
                          <NavLink
                            key={route.path}
                            className={classes.link}
                            activeClassName={classes.activeLink}
                            to={`${pathLink}${route.path}`}>
                            {route.path === 'support' && supportRequests ? (
                              <div className={classes.supportAmount}>
                                {supportRequests}
                              </div>
                            ) : null}
                            {route.icon}
                            <p className={classes.listItem}>{route.name}</p>
                          </NavLink>
                        ),
                    )}
                  </List>
                </div>
                <Divider />
                <main className={classes.content}>
                  <ErrorBoundary>
                    <Switch>
                      {mainRoutes.map(
                        route =>
                          hasPermissionForBot(
                            this.props.match.params.botId,
                            route.path,
                          ) && (
                            <Route
                              path={`${pathRoute}${route.path}`}
                              key={route.path}
                              component={route.component}
                            />
                          ),
                      )}
                      <Route
                        component={() => (
                          <PermissionDenied
                            botId={this.props.match.params.botId}
                          />
                        )}
                      />
                    </Switch>
                  </ErrorBoundary>
                </main>
              </div>
            </WebSockets>
          </BrowserRouter>
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  supportRequests: state.supportRequests,
  adminUser: state.adminUser,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(MainMenu)),
)
