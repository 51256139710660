import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { styles, customStyles } from './styles'
import { connect } from 'react-redux'

const AtomSelect = props => {
  const { flows, value, touched, onFocus, onChange } = props
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (flows) {
      const options = []

      flows.forEach(flow => {
        if (flow.atoms) {
          flow.atoms.forEach(atom => {
            options.push({ label: atom.name, value: atom.id })
          })
        }
      })

      setOptions(options)
    }
  }, [flows])

  const getValue = value => {
    return options.find(option => option.value === value)
  }

  return (
    <Select
      styles={customStyles}
      options={options}
      value={getValue(value)}
      maxMenuHeight={200}
      minMenuHeight={200}
      menuPlacement={'auto'}
      touched={touched}
      onChange={onChange}
      onFocus={onFocus}
    />
  )
}

AtomSelect.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  flows: state.flows,
})

export default withStyles(styles)(connect(mapStateToProps)(AtomSelect))
