export const styles = () => ({
    loaderWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 180
    },
    pickerContainer: {
        '& div': {
            width: '100% !important'
        }
    },
    commentsWrap: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 24px',
        overflowY: 'scroll',
        height: 300
    },
    header: {
        padding: '0px 30px 32px',
        borderBottom: '1px solid rgba(53, 64, 82, 0.1)'
    },
    footer: {
        borderTop: '1px solid rgba(53, 64, 82, 0.1)',
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    noDataWrap: {
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noData: {
        color: 'rgba(53, 64, 82, 0.5)'
    }
});
