import React from 'react'
import { IconButton, Popover, withStyles } from '@material-ui/core'
import States from '../States'
import PropTypes from 'prop-types'
import { isStringEmpty } from '../../../../helpers/isStringEmpty'
import PostbackAttributes from '../PostbackAttributes/PostbackAttributes'

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2,
  },
  paper: {
    borderRadius: 10,
    overflow: 'visible',
    padding: 16,
  },
  reply: {
    position: 'relative',
  },
  showDelete: {
    position: 'absolute',
    right: '-15px',
    top: '-10px',
    zIndex: 1,
  },
  hideDelete: {
    display: 'none',
  },
  payload: {
    margin: 0,
    fontSize: 10,
    position: 'absolute',
    top: 24,
    width: '100%',
    textAlign: 'center',
    color: 'grey',
    padding: '0 12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  title: {
    borderRadius: 35,
    color: '#2668E8',
    outline: 'none',
    textAlign: 'center',
    border: '1px solid #2668E8',
    backgroundColor: '#ffffff',
    padding: '10px 24px',
    fontSize: 14,
    lineHeight: '16px',
    resize: 'none',
    height: 36,
    width: '100%',
  },
  titlePopover: {
    borderRadius: 10,
    color: '#9B9CA3',
    outline: 'none',
    textAlign: 'center',
    border: '1px solid #9B9CA3',
    backgroundColor: '#ffffff',
    padding: '14px',
    fontSize: 14,
    lineHeight: '16px',
    resize: 'none',
    height: 40,
    width: '100%',
  },
  titles: {
    color: 'rgb(38, 104, 232)',
    fontSize: 14,
    maxHeigth: '100%',
    lineHeight: '0',
  },
  selectWrap: {
    marginTop: 16,
  },
})

class QuickReply extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      anchorOriginVertical: 'top',
      anchorOriginHorizontal: 'center',
      transformOriginVertical: 'top',
      transformOriginHorizontal: 'center',
      positionTop: 200,
      positionLeft: 400,
      anchorReference: 'anchorEl',
      showDelete: false,
    }
  }

  handleChange(value, fieldName) {
    const reply = { ...this.props.reply }
    reply[fieldName] = value
    this.props.onChange(reply)
  }

  handleClickButton = () => {
    this.setState({
      open: true,
    })
  }

  handleUpdateAttributes = attributes => {
    const reply = { ...this.props.reply }
    reply.attributes = attributes
    this.props.onChange(reply)
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  anchorEl = null

  showDelete(show) {
    this.setState({ showDelete: show })
  }

  updatePopoverPosition = () => {
    this.setState(
      {
        open: false,
      },
      () => {
        this.setState({
          open: true,
        })
      },
    )
  }

  render() {
    const {
      open,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transformOriginVertical,
      transformOriginHorizontal,
      positionTop,
      positionLeft,
      anchorReference,
    } = this.state
    const { classes, reply, rtl } = this.props
    const isRtl = rtl ? 'rtl' : 'ltr'
    const error = reply.content_type === 'text' && isStringEmpty(reply.payload)
    return (
      <div
        className={classes.reply}
        onMouseEnter={() => this.showDelete(true)}
        onMouseLeave={() => this.showDelete(false)}>
        <input
          type="text"
          className={classes.title}
          placeholder="Reply title"
          value={
            reply.content_type !== 'text' ? reply.content_type : reply.title
          }
          style={
            reply.content_type === 'text' && isStringEmpty(reply.payload)
              ? {
                border: '1px solid #f44336',
                color: '#f44336',
              }
              : {
                border: '1px solid #2668E8',
                color: '#2668E8',
              }
          }
          maxLength={40}
          ref={node => {
            this.anchorEl = node
          }}
          onClick={() => this.handleClickButton()}
          readOnly={reply.content_type !== 'text' ? 'readonly' : false}
          dir={isRtl}
        />
        <p className={classes.payload} onClick={() => this.handleClickButton()}>
          {this.props.reply.payload}
        </p>
        <IconButton
          aria-label="Delete"
          className={
            this.state.showDelete && !open
              ? classes.showDelete
              : classes.hideDelete
          }
          onClick={() => this.props.onDelete()}>
          <img
            style={{ height: 30 }}
            src="/images/platform/delete.svg"
            alt="delete"
          />
        </IconButton>
        <Popover
          open={open}
          anchorEl={this.anchorEl}
          anchorReference={anchorReference}
          anchorPosition={{ top: positionTop, left: positionLeft }}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: anchorOriginVertical,
            horizontal: anchorOriginHorizontal,
          }}
          transformOrigin={{
            vertical: transformOriginVertical,
            horizontal: transformOriginHorizontal,
          }}
          classes={{
            paper: classes.paper,
          }}>
          <div className={classes.popover}>
            <input
              type="text"
              autoFocus
              className={classes.titlePopover}
              placeholder="Reply title"
              style={{
                border: error && '1px solid #f44336',
                color: error && '#f44336',
              }}
              value={
                reply.content_type !== 'text' ? reply.content_type : reply.title
              }
              onChange={event => this.handleChange(event.target.value, 'title')}
              maxLength={40}
              readOnly={reply.content_type !== 'text' ? 'readonly' : false}
              dir={isRtl}
            />
            <IconButton
              aria-label="Delete"
              className={
                this.state.showDelete ? classes.showDelete : classes.hideDelete
              }
              onClick={() => this.props.onDelete()}>
              <img
                style={{ height: 30 }}
                src="/images/platform/delete.svg"
                alt="delete"
              />
            </IconButton>
            <div className={classes.selectWrap}>
              <States
                payload={value => this.handleChange(value, 'payload')}
                value={this.props.reply.payload}
              />
            </div>
            <PostbackAttributes
              atomAttributes={this.props.reply.attributes}
              handleUpdateAttributes={this.handleUpdateAttributes}
              callBack={this.updatePopoverPosition}
            />
          </div>
        </Popover>
      </div>
    )
  }
}

QuickReply.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(QuickReply)
