import React from 'react'
import { withStyles } from '@material-ui/core'

import { styles, SwitchStyles } from './RandomAtomsStyles'
import Switch from '@material-ui/core/Switch'
import Tooltip from '../../../../uiKit/tooltip/Tooltip'
import { InitialState } from './RandomAtomConfig'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AtomSelect from '../../../../uiKit/AtomSelect/AtomSelect'

const tooltipText =
  'The user will be redirected to the following atom if he interacts with current atom for the first time.'

const RandomAtoms = props => {
  const { classes, message, updateMessage, onDelete } = props

  const randomAtomsDisplay = message.randomRedirect.sendFirstAtomOnlyOnce
    ? message.randomRedirect.randomAtoms.filter(element => element.number !== 0)
    : message.randomRedirect.randomAtoms

  const handleSwitch = e => {
    const firstTimeRedirect = Object.assign({}, InitialState.firstTimeRedirect)
    let newRandomAtoms = [...message.randomRedirect.randomAtoms]

    if (e.target.checked) {
      newRandomAtoms = [firstTimeRedirect, ...newRandomAtoms]
    } else {
      newRandomAtoms.shift()
    }

    newRandomAtoms = newRandomAtoms.map((elem, index) => {
      elem.number = index
      return elem
    })

    message.randomRedirect.randomAtoms = newRandomAtoms
    message.randomRedirect.sendFirstAtomOnlyOnce = e.target.checked

    updateMessage(message)
  }

  const handleRandomAtoms = (e, number) => {
    let newRandomAtoms = [...message.randomRedirect.randomAtoms]

    newRandomAtoms = newRandomAtoms.map(elem => {
      if (elem.number === number) elem.atomId = e.value
      return elem
    })

    message.randomRedirect.randomAtoms = newRandomAtoms
    updateMessage(message)
  }

  const handleAddRandomAtom = () => {
    const messageCopy = deepCopyFunction(message)

    let newRandomAtoms = [...message.randomRedirect.randomAtoms]
    const newRandomAtom = {
      atomName: '',
      number: newRandomAtoms.length + 1,
    }

    newRandomAtoms = [...newRandomAtoms, newRandomAtom]

    messageCopy.randomRedirect.randomAtoms = newRandomAtoms

    updateMessage(messageCopy)
  }

  const handleDeleteRandomAtom = number => {
    let newRandomAtoms = [...message.randomRedirect.randomAtoms]

    newRandomAtoms = newRandomAtoms
      .filter(elem => elem.number !== number)
      .map((elem, index) => {
        elem.number = index
        return elem
      })

    message.randomRedirect.randomAtoms = newRandomAtoms

    updateMessage(message)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        Random redirect
        <Tooltip
          tooltipId="tooltip-random-redirect-header"
          tooltipText="Randomly redirects the user to one of the following atoms."
        />
      </div>

      <div className={classes.body}>
        <div className={classes.switcherWrap}>
          <div>
            First time redirect
            <Tooltip
              tooltipId="tooltip-random-redirect-body"
              tooltipText={tooltipText}
            />
          </div>
          <Switch
            classes={SwitchStyles}
            color="primary"
            size="small"
            checked={message.randomRedirect.sendFirstAtomOnlyOnce}
            onChange={handleSwitch}
          />
        </div>

        {message.randomRedirect.sendFirstAtomOnlyOnce && (
          <AtomSelect
            value={message.randomRedirect.randomAtoms[0].atomId}
            onChange={e => handleRandomAtoms(e, 0)}
          />
        )}
        <div>
          Random atoms
          {randomAtomsDisplay.map(element => (
            <div className={classes.atomWrap} key={element.number}>
              {message.randomRedirect.randomAtoms.length > 1 && (
                <i
                  className={classes.deleteRandomAtomWrap}
                  onClick={() => handleDeleteRandomAtom(element.number)}>
                  <img
                    src="/images/platform/delete.svg"
                    alt="delete"
                    height="30"
                  />
                </i>
              )}

              <AtomSelect
                value={element.atomId}
                onChange={e => handleRandomAtoms(e, element.number)}
              />
            </div>
          ))}
        </div>

        {message.randomRedirect.randomAtoms.length < 25 && (
          <div className={classes.addButton} onClick={handleAddRandomAtom}>
            + Add random atom
          </div>
        )}
      </div>

      <div className="dlt-msg" onClick={onDelete}>
        <img src="/images/platform/delete.svg" alt="delete" />
      </div>
    </div>
  )
}

export default withStyles(styles)(RandomAtoms)
