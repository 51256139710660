import React from 'react'
import { withStyles } from '@material-ui/core'
import moment from 'moment'

import { styles } from './CommentStyles'
import PopoverSupport from '../PopoverSupport/PopoverSupport'

const Comment = props => {
  const { classes, data } = props
  const date = moment(data.date).format('MMM DD, YYYY')

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.nameContainer}>
          <p className={classes.nameWrap}>
            {data.firstName} {data.lastName} <span>{date}</span>
          </p>
          <PopoverSupport userId={data.userId} messageId={data.messageId} />
        </div>
        <div className={classes.score}>{'★'.repeat(data.score)}</div>
        <div className={classes.comment}>{data.comment}</div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Comment)
