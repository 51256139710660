/**
 * Created by dshevchenko on 15.04.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Level from './Level'
import {
  updateBotPersistentMenu,
  getBotPersistentMenu,
} from '../../../home/api/bots'
import { setActiveBot } from '../../../home/actions/activeBot'
import { getFlows } from '../../../flowBuilder/api/flow'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall'
import Heading from '../../../../uiKit/texts/Heading'
import { getBotLanguages } from '../../api/settings'
import Select from 'react-select'

const styles = theme => ({
  paragraph: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#616581',
  },
  headerSelectorAndButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: '40%',
    height: '100%',
  },
  containerLevels: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

class PersistentMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      persistentMenu: null,
      selectedLanguageOption: {
        value: 'EN',
        label: 'English',
      },
    }
  }

  componentDidMount() {
    //TODO change get Menu depend's to messenger configs
    if (this.props.flows.length === 0) {
      getFlows(this.props.match.params.botId)
    }
    getBotPersistentMenu(
      this.props.activeBot.id,
      this.state.selectedLanguageOption.value,
      persistentMenu => {
        this.updatePersistentMenu(persistentMenu)
        this.setLanguageOptions(this.props.match.params.botId)
      },
    )
  }

  updatePersistentMenu = persistentMenu => {
    const bot = JSON.parse(JSON.stringify(this.props.activeBot))
    bot.persistentMenu = persistentMenu
    this.setState(
      {
        persistentMenu: persistentMenu,
      },
      () => {
        setActiveBot(bot)
      },
    )
  }

  setLanguageOptions = botId => {
    getBotLanguages(this.props.activeBot.id).then(botLanguages => {
      if (botLanguages.defaultLanguage) {
        const { defaultLanguage, activeLanguages } = botLanguages
        const selectedOption = {
          label: defaultLanguage.fullName,
          value: defaultLanguage.shortName,
        }

        const languageOptions = activeLanguages.map(language => {
          return {
            label: language.fullName,
            value: language.shortName,
          }
        })

        this.setState({
          languageOptions: languageOptions,
          selectedLanguageOption: selectedOption,
        })
      }
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activeBot.persistentMenu && state.persistentMenu === null) {
      return {
        persistentMenu: JSON.parse(
          JSON.stringify(props.activeBot.persistentMenu),
        ),
      }
    }
    if (!props.activeBot.persistentMenu && state.persistentMenu === null) {
      return {
        persistentMenu: {
          callToActions: [],
        },
      }
    }
    return null
  }

  handleUpdatePersistentMenu = value => {
    this.setState({
      persistentMenu: value,
    })
  }

  updateMenu = (item, index) => {
    const menu = JSON.parse(JSON.stringify(this.state.persistentMenu))
    menu.callToActions[index] = item
    this.handleUpdatePersistentMenu(menu)
  }

  deleteItem = index => {
    const menu = JSON.parse(JSON.stringify(this.state.persistentMenu))
    menu.callToActions.splice(index, 1)
    this.handleUpdatePersistentMenu(menu)
  }

  addMenuItem = item => {
    const menu = JSON.parse(JSON.stringify(this.state.persistentMenu))
    menu.callToActions.push(item)
    this.handleUpdatePersistentMenu(menu)
  }

  onUpdatePersistentMenu = () => {
    const { persistentMenu } = this.state
    persistentMenu.locale = 'default'
    updateBotPersistentMenu(
      this.props.match.params.botId,
      this.state.selectedLanguageOption.value,
      persistentMenu,
    )
  }

  handleSelectedLanguage = languageOption => {
    this.setState(
      {
        selectedLanguageOption: languageOption,
      },
      () => {
        getBotPersistentMenu(
          this.props.activeBot.id,
          this.state.selectedLanguageOption.value,
          this.updatePersistentMenu,
        )
      },
    )
  }

  render() {
    const { classes } = this.props
    const { persistentMenu } = this.state
    return (
      <div>
        <div>
          <div style={{ maxWidth: 640 }}>
            <Heading>Main Menu</Heading>
            <p className={classes.paragraph}>
              The main menu allows users to access the core functionality of
              your bot (e.g. Customer Support, Help, FAQ and others).
            </p>
          </div>
          <div>
            {persistentMenu != null ? (
              <div className={classes.containerLevels}>
                <Level
                  item={persistentMenu}
                  level={1}
                  addMenuitem={item => this.addMenuItem(item)}
                  updateMenu={(item, index) => this.updateMenu(item, index)}
                  deleteItem={index => this.deleteItem(index)}
                />
              </div>
            ) : (
              <div
                style={{
                  minHeight: 250,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <LoaderSmall showLoader />
              </div>
            )}

            <div style={{ margin: '30px 0 230px' }}>
              <div className={classes.headerSelectorAndButton}>
                <div style={{ width: '220px', marginRight: '16px' }}>
                  <Select
                    options={this.state.languageOptions}
                    value={this.state.selectedLanguageOption}
                    onChange={this.handleSelectedLanguage}
                  />
                </div>
                <SubmitButton
                  onClick={this.onUpdatePersistentMenu}
                  title="Save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PersistentMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  flows: state.flows,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(PersistentMenu),
  ),
)
