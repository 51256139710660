import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { NoChartDataIcon } from '../../../uiKit/icons/Icons'

const NoChartData = ({ text }) => (
  <div
    style={{
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
    }}>
    <NoChartDataIcon />
    <p
      style={{
        fontSize: 14,
        fontFamily: 'Lato, sans-serif',
        color: '#616581',
        textAlign: 'center',
        margin: '16px 0 0',
      }}>
      {text}
    </p>
  </div>
)

NoChartData.propTypes = {
  text: PropTypes.string.isRequired,
}

export default withRouter(NoChartData)
