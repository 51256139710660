import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { AttachmentIcon } from '../../../../uiKit/icons/AttachmentIcon'
import { selectedFile } from '../../../flowBuilder/api/fileService'

import { styles } from './styles'
import { SharedWebsocketContext } from '../../../../contexts/webSockets'
import { connect } from 'react-redux'

const UploadButton = props => {
  const { classes, user, disabled, adminUserId } = props
  const { sendMessage } = useContext(SharedWebsocketContext)

  const handleSelectedFile = event => {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('file', file)

    selectedFile(formData).then(res => {
      const message = {
        adminId: adminUserId,
        recipient: user.userId,
        fileUrl: res.url,
        fileName: file.name,
      }
      sendMessage(message)
    })
  }

  return (
    <>
      <label className={classes.svgAttachmentHolder} htmlFor="upload">
        <AttachmentIcon />
      </label>
      <input
        id="upload"
        className={classes.uploadInput}
        type="file"
        name="file-upload"
        disabled={disabled}
        onChange={handleSelectedFile}
      />
    </>
  )
}

UploadButton.propTypes = {
  classes: PropTypes.object,
  adminUserId: PropTypes.number,
}

const mapStateToProps = state => ({
  user: state.activeUser,
  adminUserId: state.adminUser.id,
})

export default withStyles(styles)(connect(mapStateToProps)(UploadButton))
