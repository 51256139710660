import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import Input from '../../../../uiKit/inputs/Input'
import { login } from '../../api/dialogflow'

const styles = theme => ({
  mb16: {
    marginBottom: 16,
  },
  mb32: {
    marginBottom: 32,
  },
  description: {
    fontSize: 12,
    color: '#B0B2C0',
    fontFamily: 'Lato, sans-serif',
    margin: '4px 0 0',
  },
  inputTitle: {
    marginTop: 3,
  },
  errorAlert: {
    color: theme.palette.alerts.error,
    marginBottom: '-10px',
    fontSize: 12,
  },
})

class DialogflowIntegrationTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      projectId: '',
      tokenSet: false,
      showLoader: false,
      isError: false,
      errorMessage: '',
      dialogflowConfigs: {},
      loginParams: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const projectId = prevState.projectId

    if (!prevState.tokenSet && nextProps.activeBot.dialogflowConfigs) {
      const { dialogflowConfigs } = nextProps.activeBot
      const newProjectId = dialogflowConfigs.projectId

      if (projectId !== newProjectId) {
        return { dialogflowConfigs, tokenSet: true }
      }
    }

    return null
  }

  onSubmit = () => {
    const bot = { ...this.props.activeBot }
    login(bot.id, this.state.dialogflowConfigs.projectId)
  }

  successCallback = () => {
    this.setState({
      isError: false,
      showLoader: false,
    })
  }

  errorCallback = errorMessage => {
    this.setState({
      isError: true,
      errorMessage: errorMessage,
    })
  }

  handleChange = (value, name) => {
    const dialogflowConfigs = { ...this.state.dialogflowConfigs }
    dialogflowConfigs[name] = value.trim()
    this.setState({
      dialogflowConfigs: { ...dialogflowConfigs },
      isError: false,
    })
  }

  render() {
    const { classes, derivedClasses, buttonStyles } = this.props
    const { isError, errorMessage, dialogflowConfigs } = this.state

    return (
      <div>
        <div className={classes.mb32}>
          <Input
            title="Project ID"
            value={dialogflowConfigs.projectId}
            maxLength={255}
            autofocus
            handleEnterPress={this.shareBot}
            inputTitleStyles={{ marginTop: 0 }}
            placeholder="Enter Google Project ID"
            onChange={event =>
              this.handleChange(event.target.value, 'projectId')
            }
          />
          <p className={classes.description}>
            Find Google Project ID in DialogFlow Settings page
          </p>
          {isError && <p className={classes.errorAlert}>{`${errorMessage}`}</p>}
        </div>
        <div className={`${derivedClasses.tabInnerContainer} ${classes.mb16}`}>
          <div className={derivedClasses.buttonHolder}>
            <SubmitButton
              title="Connect"
              type={buttonStyles.type || 'secondary'}
              size="sm"
              onClick={this.onSubmit}
            />
          </div>
        </div>
      </div>
    )
  }
}

DialogflowIntegrationTemplate.defaultProps = {
  derivedClasses: {},
  loginParams: {},
  classes: {},
  activeBot: {},
  buttonStyles: {},
}

DialogflowIntegrationTemplate.propTypes = {
  classes: PropTypes.object,
  derivedClasses: PropTypes.object,
  loginParams: PropTypes.object,
  activeBot: PropTypes.object,
  buttonStyles: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(DialogflowIntegrationTemplate),
  ),
)
