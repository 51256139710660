import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { assignSupportRequest } from '../../api/user'
import { saveActiveUser } from '../../actions/activeUser'
import { styles } from './styles'

const AssignToMeButton = props => {
  const { classes, user, activeBot, title, adminUserId } = props

  const handleClick = () => {
    const json = {
      supportRequestId: user.supportRequest?.id,
      userId: user.userId,
      adminId: adminUserId,
    }
    const userCopy = Object.assign({}, user)

    userCopy.supportRequest = {
      status: 'ASSIGNED',
      support: {
        adminId: adminUserId,
      },
      userId: user.userId,
    }

    assignSupportRequest(activeBot.id, json).then(() => {
      saveActiveUser(userCopy)
    })
  }

  return (
    <div className={classes.container} onClick={handleClick}>
      {title}
    </div>
  )
}

AssignToMeButton.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  activeBot: PropTypes.object,
  adminUserId: PropTypes.number,
  title: PropTypes.string,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  adminUserId: state.adminUser.id,
  user: state.activeUser,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(AssignToMeButton)),
)
