import { setBotWidget } from '../actions/botWidget'
import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { BASE_URL, BACKEND_URL } from '../../../configs'
import * as cookie from 'react-cookies'

export function getWidget(botId, language) {
  fetch(`${BASE_URL}/bot/${botId}/widget?language=${language}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setBotWidget(json)
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting widget info, please ping support!',
      )
    })
}
export function updateWidget(botId, widget, language) {
  fetch(`${BASE_URL}/bot/${botId}/widget?language=${language}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(widget),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setBotWidget(json)
      alertSuccess('Widget settings updated', 3000)
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting widget info, please ping support!',
      )
    })
}

export function handleDrop(file, callback) {
  fetch(`${BASE_URL}/file/widgetLogo`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: { Authorization: 'Bearer ' + cookie.load('YWxpYWFkbWlu') },
    body: file,
  })
    .then(response =>
      (response.redirected
        ? (window.location.href = response.url)
        : response.text()),
    )
    .then(response => {
      callback(response)
    })
    .catch(error => {
      alertError('Fail. Try it later', 10000)
    })
}

export const downloadWidget = botId => {
  window.open(`${BACKEND_URL}/downloadWidget/${botId}`, '_blank')
}
