import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { BASE_URL, HOME_PATH } from '../../../configs'
import { access } from '../../../security'
import { updateDialogFlowConfigs } from '../actions/activeBot'
import { setActiveBot } from '../actions/activeBot'
import { saveBots } from '../actions/bots'

export function getBots(callback) {
  fetch(BASE_URL + '/bot', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveBots(json)
      if (callback) callback()
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support!')
    })
}

export function getBotConfigs(botId) {
  fetch(BASE_URL + '/bot/' + botId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setActiveBot(json)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support!')
    })
}

export function getBot(botId) {
  return fetch(BASE_URL + '/bot/' + botId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setActiveBot(json)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support!')
    })
}

export function getDashboard(botId, state) {
  fetch(BASE_URL + '/bot/' + botId + '/dashboard', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      state.setState({ ...json })
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support!')
    })
}

export function deleteBot(botId) {
  fetch(BASE_URL + '/bot/' + botId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
    .then(response => {
      window.location.href = HOME_PATH
      return response
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function createNewBotAPI(bot, callback, callbackError) {
  fetch(BASE_URL + '/bot', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(bot),
  }).then(response => {
    logoutOnRequestOrResponseJson(response)
    if (response.ok) {
      alertSuccess('Bot is created successfully.')
      getBots()
      access.loadRole()
      if (callback) callback()
    } else if (response.status === 400 || response.status === 500) {
      response.json().then(text => callbackError(text.message))
    }
    return response.redirected
      ? (window.location.href = response.url)
      : response
  })
}

export function shareBot(botId, json, callback) {
  fetch(BASE_URL + '/bot/' + botId + '/shareBot', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(() => {
      if (callback) callback()
      alertSuccess('Bot is shared successfully.')
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function cloneBot(botId, json, callback) {
  fetch(BASE_URL + '/bot/' + botId + '/clone', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      alertSuccess('Bot is cloned successfully.')
      access.loadRole()
      getBots()
      if (callback) {
        callback()
      }
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function updateBot(bot, callback) {
  fetch(BASE_URL + '/bot', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(bot),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setActiveBot(json)
      if (callback) callback()
      alertSuccess('Bot is updated successfully.')
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function getBotPersistentMenu(botId, language, callback) {
  fetch(BASE_URL + '/bot/' + botId + '/persistentMenu?language=' + language, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      return response.redirected
        ? (window.location.href = response.url)
        : response.json()
    })
    .then(json => {
      if (callback) callback(json)
    })
    .catch(function(error) {
      alertError('Failed to get the persistent menu.')
    })
}

export function updateBotPersistentMenu(botId, language, persistentMenu) {
  persistentMenu.locale = 'default' //todo check logic on persistent component
  fetch(BASE_URL + '/bot/' + botId + '/persistentMenu?language=' + language, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(persistentMenu),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Bot is updated successfully.')
      setActiveBot(json)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function updateDialogflowConfigs(
  botId,
  dialogFlowConfigs,
  successCallback,
  errorCallback,
) {
  fetch(BASE_URL + '/bot/' + botId + '/updateDialogflowConfigs', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PATCH',
    body: JSON.stringify(dialogFlowConfigs),
  })
    .then(response => {
      if (response.ok) {
        response.redirected
          ? (window.location.href = response.url)
          : alertSuccess('Bot is updated successfully.')
        updateDialogFlowConfigs(dialogFlowConfigs)
        if (successCallback) successCallback()
      } else if (errorCallback) {
        response.json().then(err => errorCallback(err.message))
      }
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function updateWitAiConfigsRequest(
  botId,
  successCallback,
  errorCallback,
) {
  fetch(BASE_URL + '/bot/' + botId + '/witAiConfigs', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
  })
    .then(response => {
      if (response.ok) {
        response.redirected
          ? (window.location.href = response.url)
          : alertSuccess('Bot is updated successfully.')
        if (successCallback) successCallback()
      } else if (errorCallback) {
        response.json().then(err => errorCallback(err.message))
      }
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function updateGreetingText(
  botId,
  greetingText,
  successCallback,
  errorCallback,
) {
  fetch(BASE_URL + '/bot/' + botId + '/greetingText', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PATCH',
    body: JSON.stringify({ greetingText }),
  })
    .then(response => {
      if (response.ok) {
        response.redirected
          ? (window.location.href = response.url)
          : alertSuccess('Greeting text is updated successfully.')
        if (successCallback) successCallback()
      } else if (errorCallback) {
        response.json().then(err => errorCallback(err.message))
      }
    })
    .catch(function(error) {
      alertError('Failed. Try it later')
    })
}
