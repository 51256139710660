import cookie from 'react-cookies'

import { showSnackBar } from '../tabs/home/actions/snackBar'
import { logout } from '../tabs/authorization/actions/security'

export function createJsonHeaders() {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  headers.append('Authorization', 'Bearer ' + cookie.load('YWxpYWFkbWlu'))
  return headers
}

export function logoutOnRequestOrResponseJson(response) {
  if (response.ok) {
    return response.redirected ? logout() : response.json()
  }
}

export function alertSuccess(message, duration) {
  showSnackBar({
    open: true,
    text: message,
    success: true,
    duration,
  })
}

export function alertError(message) {
  showSnackBar({
    open: true,
    text: message,
    success: false,
  })
}
