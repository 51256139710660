export const styles = () => ({
  wrap: {
    width: 328,
  },
  button: {
    backgroundColor: '#ffffff',
    borderTop: '1px solid #E5E5E5',
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0560A9',
    fontWeight: 'bold',
  },
  lastButton: {
    backgroundColor: '#ffffff',
    borderTop: '1px solid #E5E5E5',
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0560A9',
    fontWeight: 'bold',
    borderRadius: '0px 0px 8px 8px',
  },
})
