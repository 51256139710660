import React from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import 'react-chartjs-2'
import AreaChart from './components/usersChart/AreaChart'
import CircleChart from './components/CircleChart'
import HourlyChart from './components/hourlyChart'
import Headline from '../../uiKit/texts/Headline'

import { styles } from './components/Dashboard-styles'

const Dashboard = ({ classes, match }) => {
  const { botId } = match.params

  return (
    <div className={classes.dashboard}>
      <Headline title="Dashboard" />
      <div className={classes.charts}>
        <div className={classes.chartsRow}>
          <AreaChart
            botId={botId}
            className={`${classes.chart} ${classes.chartFull}`}
          />
        </div>

        <div className={classes.chartsRow}>
          <CircleChart botId={botId} className={classes.chart} />
          <HourlyChart botId={botId} className={classes.chart} />
        </div>
      </div>
    </div>
  )
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles, { withTheme: true })(Dashboard))
