import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import Input from '../../../../uiKit/inputs/Input'
import { getChatbaseConfig, updateChatbaseConfig } from '../../api/chatbase'
import { isStringEmpty } from '../../../../helpers/isStringEmpty'

const styles = theme => ({
  mb16: {
    marginBottom: 16,
  },
  mb32: {
    marginBottom: 32,
  },
  description: {
    fontSize: 12,
    color: '#B0B2C0',
    fontFamily: 'Lato, sans-serif',
    margin: '4px 0 0',
  },
  inputTitle: {
    marginTop: 3,
  },
  errorAlert: {
    color: theme.palette.alerts.error,
    marginBottom: '-10px',
    fontSize: 12,
  },
})

class ChatbaseIntegrationTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      apiKey: '',
      isError: false,
      errorMessage: '',
      showLoader: false,
      chatBaseConfigs: {},
    }
  }

  componentDidMount() {
    getChatbaseConfig(this.props.match.params.botId).then(data => {
      if (data) {
        this.setState({ chatBaseConfigs: data })
      }
    })
  }
  onSubmit = () => {
    const chatBaseConfigs = this.state.chatBaseConfigs

    if (this.checkInputFields()) {
      this.toggleLoader()
      updateChatbaseConfig(
        this.props.match.params.botId,
        chatBaseConfigs,
        this.toggleLoader,
      )
    }
  }

  checkInputFields = () => {
    let botError = true
    const { apiKey } = this.state.chatBaseConfigs

    if (isStringEmpty(apiKey)) {
      botError = false
      this.setState({
        isError: true,

        errorMessage: 'Chatbase Api Key cannot be an empty field!',
      })
    }
    return botError
  }

  handleChange = (value, name) => {
    const { chatBaseConfigs } = this.state
    chatBaseConfigs[name] = value.trim()
    this.setState({
      chatBaseConfigs,
      isError: false,
    })
  }

  toggleLoader = () => {
    this.setState({ showLoader: !this.state.showLoader })
  }

  render() {
    const { classes, derivedClasses, buttonStyles } = this.props
    const { isError, errorMessage, chatBaseConfigs } = this.state

    return (
      <div>
        <div className={classes.mb32}>
          <Input
            title="Api Key"
            value={chatBaseConfigs ? chatBaseConfigs.apiKey : null}
            maxLength={255}
            autofocus
            handleEnterPress={this.onSubmit}
            inputTitleStyles={{ marginTop: 0 }}
            placeholder="Enter Chatbase Api Key"
            onChange={event => this.handleChange(event.target.value, 'apiKey')}
          />
          <p className={classes.description}>
            Find Chatbase api key in Chatbase bots main page
          </p>
          {isError && <p className={classes.errorAlert}>{`${errorMessage}`}</p>}
        </div>
        <div className={`${derivedClasses.tabInnerContainer} ${classes.mb16}`}>
          <div className={derivedClasses.buttonHolder}>
            <SubmitButton
              title="Connect"
              type={buttonStyles.type || 'secondary'}
              size="sm"
              onClick={this.onSubmit}
            />
          </div>
        </div>
      </div>
    )
  }
}

ChatbaseIntegrationTemplate.defaultProps = {
  derivedClasses: {},
  classes: {},
  buttonStyles: {},
}

ChatbaseIntegrationTemplate.propTypes = {
  classes: PropTypes.object,
  derivedClasses: PropTypes.object,
  buttonStyles: PropTypes.object,
}

export default withRouter(
  withStyles(styles, { withTheme: true })(ChatbaseIntegrationTemplate),
)
