import React from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import {styles, getWidth} from "./VotesLineStyles";

const VotesLine = props => {
    let {classes, upvotes, downvotes} = props;

    const votesLineUpWidth = upvotes * 100 / (upvotes + downvotes);
    const votesLineDownWidth = downvotes * 100 / (upvotes + downvotes);

    return (
        <div className={classes.votesContainer}>
            <div className={classes.votesWrap}>
                <div>👍 {upvotes}</div>
                <div>👎 {downvotes}</div>
            </div>
            <div className={classes.votesWrapLine}>
                {!upvotes && !downvotes
                && <div className={classes.noVotes}/>}

                {!!upvotes
                && <div className={classes.votesLineUp}
                        style={getWidth(votesLineUpWidth)}/>}

                {!!downvotes
                && <div className={classes.votesLineDown}
                        style={getWidth(votesLineDownWidth)}/>}
            </div>
        </div>
    );
};

export default withRouter(withStyles(styles)(VotesLine));
