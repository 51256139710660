import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { HolderStyles, styles, TabTemplateStyles } from './IntentModalStyles'
import { createNewIntent, getIntentUserSays, updateIntent } from '../../api/nlp'
import { getBotLanguages } from '../../../settings/api/settings'
import InputHolder from '../../../../uiKit/inputs/InputHolder'
import TabTemplate from '../../../widget/components/TabTemplate'
import { EnterIcon } from '../../../../uiKit/icons/Icons'
import UserSays from '../UserSays'
import States from '../../../flowBuilder/components/States'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { alertError } from '../../../../api'
import Loader from '../../../../uiKit/loaders/loader'
import { isStringEmpty } from '../../../../helpers/isStringEmpty'

class IntentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      intentName: null,
      userSays: [],
      answerAtom: null,
      intentId: null,
      faq: null,
      expanded: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { modalIntentId, match } = this.props

    getBotLanguages(match.params.botId)
      .then(botLanguages => {
        if (botLanguages) {
          const { activeLanguages } = botLanguages
          this.setState({
            botActiveLanguagesList: activeLanguages,
            userSays: this.createUserSays(activeLanguages),
          })
        }
      })
      .then(() => {
        if (modalIntentId != null) {
          this.setState({
            loading: true,
          })

          getIntentUserSays(this.props.modalIntentId).then(json => {
            if (json) {
              const { name, answerAtom, intentId, faq, userSays } = json

              this.setState({
                intentName: name,
                userSays: userSays,
                answerAtom: answerAtom,
                intentId: intentId,
                faq: faq,
                loading: false,
              })
            }
          })
        }
      })
  }

  convert = back => {
    const converted = []

    for (const [key, value] of Object.entries(back)) {
      converted.unshift({
        isEdited: false,
        shortName: key,
        phrases: value,
      })
    }

    return converted
  }

  createUserSays = languages => {
    const userSays = []
    languages.forEach(language => {
      userSays.push({
        isEdited: false,
        shortName: language.shortName,
        phrases: [],
      })
    })
    return userSays
  }

  handleIntentName = event => {
    this.setState({
      intentName: event.target.value,
    })
  }

  handleChangeExpansion = expanded => {
    this.setState({
      expanded: expanded === this.state.expanded ? null : expanded,
      text: '',
    })
  }

  handleChangeInput = e => {
    this.setState({
      text: e.target.value,
    })
  }

  handleChangeAtom = value => {
    this.setState({ answerAtom: value })
  }

  addUserSays = (event, languageCode) => {
    const { userSays, text } = this.state
    let newUserSays = [...userSays]
    const currentUserSays =
      newUserSays?.find(elem => elem.shortName === languageCode).phrases || []

    if (
      (event.type === 'keypress' && event.charCode === 13) ||
      event.type === 'click'
    ) {
      let found = false

      currentUserSays.forEach(elem => {
        if (
          elem.trim().toUpperCase() === this.state.text.trim().toUpperCase() &&
          !found
        ) {
          found = true
          this.setState({
            notValue: this.state.text,
            showNotification: true,
          })
        }
      })

      if (!found && this.state.text !== '') {
        newUserSays = newUserSays.map(elem => {
          if (elem.shortName === languageCode) {
            elem.phrases.unshift(text)
            elem.isEdited = true
          }
          return elem
        })

        this.setState({
          userSays: newUserSays,
          showNotification: false,
          text: '',
        })
        return
      }
    }
  }

  editUserPhrase = (index, languageCode, event) => {
    let found = false
    const { userSays } = this.state
    let newUserSays = [...userSays]
    const currentUserSays = newUserSays.find(
      elem => elem.shortName === languageCode,
    ).phrases
    for (let i = 0; i < currentUserSays.length && !found; i++) {
      if (
        currentUserSays[i].trim().toUpperCase() ===
          event.target.value.trim().toUpperCase() &&
        index !== i
      ) {
        found = true
        this.setState({
          notValue: event.target.value,
          showNotification: true,
        })
        this.modifyInput(index)
        break
      }
    }

    if (!found && event.target.value !== '') {
      newUserSays = newUserSays.map(elem => {
        if (elem.shortName === languageCode) {
          elem.phrases.splice(index, 1, event.target.value)
          elem.isEdited = true
        }
        return elem
      })
      this.setState({
        userSays: newUserSays,
        showNotification: false,
      })
      this.modifyInput(null)
      return
    }
  }

  deleteUserPhrase = (index, languageCode) => {
    const { userSays } = this.state
    const newUserSays = [...userSays]

    newUserSays[languageCode] = newUserSays
      .find(elem => elem.shortName === languageCode)
      .phrases.splice(index, 1)

    this.setState(
      {
        userSays: newUserSays,
      },
      () => this.modifyInput(null),
    )
  }

  getTabInfo = language => {
    const { userSays } = this.state
    const { fullName, shortName } = language

    return {
      name: fullName,
      phrasesNumber:
        userSays.length &&
        userSays.find(elem => elem.shortName === shortName).phrases.length,
    }
  }

  modifyInput = id => {
    this.setState({
      editInput: id,
    })
  }

  setEmptySay = index => {
    this.setState({
      emptySay: index,
    })
  }

  createIntent = () => {
    if (isStringEmpty(this.state.intentName)) {
      alertError(
        `please write ${this.props.isFaqModal ? 'FAQ' : 'Intent'} name`,
      )
      return
    }
    if (this.props.isFaqModal && this.state.answerAtom == null) {
      alertError('please choose answer')
      return
    }
    let unique = true
    this.props.intents.forEach(intent => {
      if (
        intent.name.toUpperCase() === this.state.intentName.toUpperCase() &&
        intent.id !== this.props.modalIntentId
      ) {
        alertError(`Intent with name '${intent.name}' already exist`, 10000)
        unique = false
        return
      }
    })
    if (unique == true) {
      const intent = {
        name: this.state.intentName,
        intentId: this.state.intentId,
        botId: this.props.match.params.botId,
        userSays: this.state.userSays,
        answerAtom: this.state.answerAtom,
        faq: this.props.isFaqModal,
      }
      if (this.props.modalIntentId == null) {
        createNewIntent(intent, this.props.intentsSize)
      } else {
        intent.id = this.props.modalIntentId
        updateIntent(intent)
      }
      this.props.closeIntent()
    }
  }

  render() {
    const { classes, modalIntentId, isFaqModal } = this.props
    const {
      intentName,
      botActiveLanguagesList,
      expanded,
      text,
      showNotification,
      notValue,
      emptySay,
      editInput,
      userSays,
      loading,
    } = this.state
    const modalName = isFaqModal ? 'FAQ' : 'Intent'

    return (
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <h2 className={classes.heading}>
              {modalIntentId != null ? `Edit ${intentName}` : 'Create new'}{' '}
              {modalName}
            </h2>
            <p className={classes.modalHeading}>{modalName} name</p>
            <InputHolder
              placeholder="Type name"
              maxLength={90}
              holderStyle={HolderStyles}
              defaultValue={intentName}
              onChange={this.handleIntentName}
            />

            <p className={classes.modalHeading}>Questions</p>
            {botActiveLanguagesList &&
              botActiveLanguagesList.map(language => (
                <TabTemplate
                  key={language.id}
                  tab={this.getTabInfo(language)}
                  expanded={expanded === language.shortName}
                  onChange={() =>
                    this.handleChangeExpansion(language.shortName)
                  }
                  detailsStyle={TabTemplateStyles}>
                  <div className={classes.inputHolder}>
                    <input
                      maxLength={255}
                      className={`${classes.input} addPhrase`}
                      value={text}
                      ref={node => {
                        this.textInput = node
                      }}
                      dir={language.isRtl && 'rtl'}
                      placeholder="+ Add user phrase and press Enter to add it"
                      onKeyPress={(event, text) =>
                        this.addUserSays(event, language.shortName, text)
                      }
                      onChange={this.handleChangeInput}
                    />
                    <div
                      onClick={e => {
                        this.addUserSays(e, language.shortName)
                      }}
                      className={`${classes.enter} enter`}>
                      <EnterIcon width={14} height={14} color="#11C314" />
                    </div>
                  </div>
                  {showNotification && (
                    <p className={classes.alert}>
                      {`User Phrase '${notValue}' already exist`}
                    </p>
                  )}

                  <div className={classes.userSaysWrapper}>
                    {!!userSays.length &&
                      userSays
                        .find(elem => elem.shortName === language.shortName)
                        .phrases.map((says, id) => (
                          <div key={says}>
                            <UserSays
                              says={says}
                              isRtl={language.isRtl}
                              emptySay={emptySay}
                              editInput={editInput}
                              modifyInput={this.modifyInput}
                              setEmptySay={this.setEmptySay}
                              editUserPhrase={(id, e) =>
                                this.editUserPhrase(id, language.shortName, e)
                              }
                              deleteUserPhrase={(id, e) =>
                                this.deleteUserPhrase(id, language.shortName, e)
                              }
                              index={id}
                            />
                          </div>
                        ))}
                  </div>
                </TabTemplate>
              ))}
            {isFaqModal && (
              <div style={{ marginTop: 20 }}>
                <p className={classes.modalHeading}>Answers</p>
                <States
                  payload={this.handleChangeAtom}
                  style={{ marginTop: 20 }}
                  value={this.state.answerAtom}
                  isCurrent={true}
                />
              </div>
            )}
            <SubmitButton
              styles={{ margin: '30px auto 0', display: 'block' }}
              onClick={this.createIntent}
              title={modalIntentId == null ? 'Create' : 'Save'}
            />
          </div>
        )}
      </div>
    )
  }
}

IntentModal.propTypes = {
  classes: PropTypes.object.isRequired,
  intents: PropTypes.array.isRequired,
  intentsSize: PropTypes.number.isRequired,
  tabIndex: PropTypes.string.isRequired,
  modalIntentId: PropTypes.number.isRequired,
  isFaqModal: PropTypes.bool.isRequired,
  closeIntent: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles)(IntentModal))
