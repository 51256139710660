import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { shareBot } from '../api/bots'
import { loadBotAccessors } from '../../settings/api/settings'
import { ROLE_OPTIONS } from '../../../security'

const styles = theme => ({
  closeContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  statesModal: {
    height: 45,
    padding: '10px 0',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    flexBasis: '60%',
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  input: {
    padding: '10px',
    border: 'none',
    outline: 'none',
    width: '90%',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
    color: '#1658F3',
  },
  enter: {
    width: '10%',
    cursor: 'pointer',
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: '100%',
  },
  save: {
    padding: '12px 28px',
    border: 0,
    background: '#1658F3',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    borderRadius: 10,
    cursor: 'pointer',
    color: 'white',
    margin: '30px auto 0',
    display: 'block',
  },
  modalHeading: {
    fontSize: 16,
    color: '#3A3F62',
    fontWeight: 400,
    marginTop: 24,
  },
  none: {
    display: 'none',
  },
})

class ShareModal extends React.Component {
  state = {
    email: '',
    text: '',
    role: ROLE_OPTIONS[0].value,
  }

  updateRole(value) {
    this.setState({
      role: value,
    })
  }

    checkEmail() {
      let isCorrect = false;
      if (this.state.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-][a-zA-Z0-9-.]+[a-zA-Z0-9-]$") != null) {
        const testArrBegPos = this.state.email.indexOf("@") + 1
        const testArr = this.state.email.slice(testArrBegPos, this.state.email.length)
        if(!(testArr.includes('..') || !testArr.includes('.')))
          isCorrect = true;
      }

      this.setState({emailError: !isCorrect});
      return isCorrect;
  }

  shareBot() {
    if (this.checkEmail()) {
      const access = {
        receiverRole: this.state.role,
        receiverEmail: this.state.email,
      }
      shareBot(this.props.bot.id, access, () => {
        this.props.closeModal()
        loadBotAccessors(this.props.bot.id)
      })
    }
  }

  handleChangeInput(e) {
    this.setState({
      email: e.target.value.trim(),
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <p className={classes.modalHeading}>Email</p>
        <div
          className={classes.inputHolder}
          style={{ borderColor: this.state.emailError ? 'red' : '#C7CAD6' }}>
          <input
            maxLength={255}
            className={classes.input}
            value={this.state.email}
            placeholder="Enter user's email"
            onChange={e => this.handleChangeInput(e)}
          />
        </div>
        {this.state.emailError && (
          <p
            style={{
              color: '#FF624C',
              marginBottom: '-10px',
              fontSize: 12,
            }}>
            Email is not valid
          </p>
        )}
        <p className={classes.modalHeading}>Role</p>
        <Select
          options={ROLE_OPTIONS}
          simpleValue
          value={ROLE_OPTIONS.find(opt => opt.value === this.state.role)}
          clearable={false}
          onChange={event => this.updateRole(event.value)}
          searchable={true}
        />
        <button onClick={this.shareBot.bind(this)} className={classes.save}>
          Share
        </button>
      </div>
    )
  }
}

ShareModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(ShareModal))
