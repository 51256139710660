import React, { useEffect, useState } from 'react'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Popper from '@material-ui/core/Popper'

import Search from '../../uiKit/Search/Search'
import AttributesModal from '../../tabs/settings/components/general/AttributesModal/AttributesModal'
import { deepCopyFunction } from '../../helpers/deepCopyFunction'
import { createAttribute } from '../../tabs/settings/api/attributes'
import AttributeItem from '../AttributeItem/AttributeItem'

import { styles } from './styles'
import {
  CHATBOT_TITLE,
  FACEBOOK_TITLE,
  TWILIO_TITLE,
  WHATSAPP_TITLE,
  WIDGET_TITLE,
} from './config'
import {
  CHATBOT,
  FACEBOOK,
  TWILIO,
  WHATSAPP,
  WIDGET,
} from '../../constants/attributeTypes'

const AttributesList = props => {
  const {
    classes,
    show,
    onClose,
    position,
    addParam,
    activeBot,
    showSearch,
    attributes,
    twilioCredentials,
    listRef,
    selectedAttribute,
    updateSelectedAttribute,
    onKeyDown,
  } = props
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])

  const isMatch = deepCopyFunction(attributes)
    ?.filter(attribute => attribute?.name?.includes(searchValue))
    ?.filter(attribute => attribute?.name?.includes(props.searchValue))?.length

  const isFacebookConnected = activeBot?.messengerConfigs?.accessToken
  const isWhatsappConnected = activeBot?.amioWhatsAppConfigs?.accessToken
  const isTwilioConnected = twilioCredentials?.connected

  useEffect(() => {
    const chatbotAttributes = getAttributeList(CHATBOT, true, CHATBOT_TITLE)
    const widgetAttributes = getAttributeList(WIDGET, true, WIDGET_TITLE)
    const facebookAttributes = getAttributeList(
      FACEBOOK,
      isFacebookConnected,
      FACEBOOK_TITLE,
    )
    const whatsappAttributes = getAttributeList(
      WHATSAPP,
      isWhatsappConnected,
      WHATSAPP_TITLE,
    )
    const twilioAttributes = getAttributeList(
      TWILIO,
      isTwilioConnected,
      TWILIO_TITLE,
    )

    setOptions([
      ...chatbotAttributes,
      ...widgetAttributes,
      ...facebookAttributes,
      ...whatsappAttributes,
      ...twilioAttributes,
    ])
  }, [attributes?.length, searchValue, props.searchValue])

  useEffect(() => {
    setSearchValue('')
  }, [show])

  useEffect(() => {
    updateSelectedAttribute(1)
  }, [searchValue])

  const getAttributeList = (type, connected, titleObject) => {
    const options = filterOptions(type)

    return options?.length && connected ? [titleObject, ...options] : []
  }

  const handleClose = attribute => {
    addParam(attribute.name)
  }

  const handleCreateAttribute = () => {
    const newAttribute = { name: searchValue || props.searchValue }

    createAttribute(activeBot.id, newAttribute).then(attribute => {
      handleClose(attribute)
    })
  }

  const filterOptions = type => {
    return attributes
      ?.filter(
        attribute =>
          attribute.type === type && attribute?.name?.includes(searchValue),
      )
      ?.filter(
        attribute =>
          attribute.type === type &&
          attribute?.name?.includes(props.searchValue),
      )
  }

  const handleOpenModal = () => {
    setOpen(true)
    onClose()
  }

  return (
    <>
      {show && (attributes?.length || searchValue || props.searchValue) && (
        <Popper
          open={Boolean(show)}
          style={position}
          className={classes.container}>
          <ClickAwayListener onClickAway={onClose}>
            <div>
              {showSearch && (
                <Search
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  onKeyDown={onKeyDown}
                  style={{ margin: 6 }}
                />
              )}
              <div
                className={classes.attributesContainer}
                onKeyDown={onKeyDown}
                tabIndex={'0'}
                ref={listRef}>
                {isMatch ? (
                  options.map((attribute, index) => (
                    <AttributeItem
                      key={attribute.id}
                      attribute={attribute}
                      onClick={handleClose}
                      selectedAttribute={selectedAttribute}
                      index={index}
                      onMouseEnter={updateSelectedAttribute}
                    />
                  ))
                ) : (
                  <div
                    className={classes.addAttibute}
                    onClick={handleCreateAttribute}>
                    <p className={classes.optionTitle}>Create new attribute:</p>
                    <p className={classes.option}>
                      {searchValue || props.searchValue}
                    </p>
                  </div>
                )}
              </div>
              <div className={classes.button} onClick={handleOpenModal}>
                Manage attibutes
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
      )}

      <AttributesModal open={open} onClose={() => setOpen(false)} />
    </>
  )
}

AttributesList.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBot: state.activeBot,
  twilioCredentials: state.twilioCredentials,
})

export default withStyles(styles)(connect(mapStateToProps)(AttributesList))
