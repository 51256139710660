import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import InteractionTimer from '../InteractionTimer/InteractionTimer'
import { MY_SUPPORT_ICON, SUPPORT_ICON } from '../UserDialogPreview/config'
import moment from 'moment'
import { CHAT_PLATFORM } from '../../../../constants/chatPlatform'

const UserNameBlock = props => {
  const { classes, user, isAssigned, isAssignedToCurrentAdmin } = props
  const supportIcon =
    (isAssignedToCurrentAdmin && MY_SUPPORT_ICON) ||
    (isAssigned && SUPPORT_ICON)

  const lastMessageDate = moment(user?.messageDatetime).isSame(
    new Date(),
    'day',
  )
    ? 'Today'
    : moment(user?.messageDatetime).format('MMM DD')

  const isFromWhatsApp = user?.platform === CHAT_PLATFORM.WHATSAPP

  return (
    <div className={classes.userMainInfo}>
      <div className={classes.userNameWrapper}>
        <p className={classes.name}>
          {user?.firstName} {user?.lastName}
        </p>

        {supportIcon && (
          <img
            src={supportIcon}
            className={classes.supportIcon}
            alt={'Support Icon'}
          />
        )}

        {isFromWhatsApp && user.userMessageDatetime && (
          <InteractionTimer lastInteractionDate={user?.userMessageDatetime} />
        )}
      </div>

      <div className={classes.lastMsgTime}>{lastMessageDate}</div>
    </div>
  )
}
UserNameBlock.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(UserNameBlock)
