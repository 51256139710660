import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Close from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getModalStyle } from '../../../../../uiKit/Modal'
import Search from '../../../../../uiKit/Search/Search'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import LaguageSelector from '../../../../../uiKit/LaguageSelector/LaguageSelector'
import SecondaryButton from '../../../../../uiKit/SecondaryButton/SecondaryButton'
import { PlusIcon } from '../../../../../uiKit/icons/PlusIcon'

import AttributesModalList from '../AttributesModalList/AttributesModalList'

import { deepCopyFunction } from '../../../../../helpers/deepCopyFunction'
import { getModifiedObject } from '../../../../../helpers/getModifiedObject'

import { alertSuccess } from '../../../../../api'
import { getAttributes, updateAttributes } from '../../../api/attributes'


import { styles } from './styles'


const style = {
  top: 50,
  left: 50,
  overflowY: 'visible',
}

const AttributesModal = props => {
  const { classes, onClose, attributes, open, activeBotId } = props
  const [searchValue, setSearchValue] = useState('')
  const [attributesCopy, setAttributesCopy] = useState([])
  const [language, setLanguage] = useState(null)
  const [error, setShowError] = useState(null)

  useEffect(() => {
    if (language?.value) {
      getAttributes(activeBotId, language?.value)
    }
  }, [language?.value])

  useEffect(() => {
    const chatbotAttributes = attributes?.filter(
      attribute => attribute?.type === 'CHATBOT',
    )
    setAttributesCopy(chatbotAttributes)
    setShowError(false)
  }, [attributes, open])

  const handleAddAttribute = () => {
    const attributesUpdate = deepCopyFunction(attributesCopy)
    const newAttribute = {
      name: '',
      defaultValue: '',
      type: 'CHATBOT',
      isNew: true,
      isValid: true,
      id: Math.random(),
    }

    attributesUpdate.push(newAttribute)
    setAttributesCopy(attributesUpdate)
  }

  const handleSaveAttributes = () => {
    if (handleValidate()) {
      const chatbotAttributes = deepCopyFunction(attributesCopy)
        ?.filter(
          attribute =>
            getModifiedObject(attributes, attribute) && attribute.name,
        )
        ?.map(attribute => {
          if (!attributes.find(x => x.id === attribute.id)) {
            const { name, defaultValue, type } = attribute

            return { name, defaultValue, type }
          }
          return attribute
        })

      updateAttributes(activeBotId, chatbotAttributes, language?.value)
        .then(() => alertSuccess('Attributes are updated successfully'))
        .then(() => onClose())
    }
  }

  const handleValidate = () => {
    const attributesUpdate = deepCopyFunction(attributesCopy)?.map(
      attribute => {
        if (attribute.isNew && !attribute.name) {
          attribute.isValid = false
        }
        return attribute
      },
    )

    const isValid = attributesUpdate
      ?.filter(attribute => attribute.isNew)
      ?.every(attribute => attribute.isValid)

    setAttributesCopy(attributesUpdate)

    return isValid
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      style={{ overflowY: 'auto' }}
      onClose={onClose}>
      <div style={getModalStyle(style)} className={classes.paper}>
        <div className={classes.header}>
          <Close className={classes.btnClose} onClick={onClose} />
          <div className={classes.titleWrap}>
            <h2 className={classes.title}>Manage attributes</h2>
            <LaguageSelector onChange={setLanguage} value={language} />
          </div>
          <Search
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            style={{ margin: '24px 0px 0px' }}
            placeholder={'Search attributes...'}
          />
        </div>
        <div className={classes.body}>
          {attributesCopy.length ? (
            <AttributesModalList
              setAttributes={setAttributesCopy}
              attributes={attributesCopy}
              searchValue={searchValue}
              error={error}
              setShowError={setShowError}
              isRtl={language?.rtl}
            />
          ) : (
            <p className={classes.noAttribute}>
              You have no chatbot attributes yet
            </p>
          )}
        </div>
        <div className={classes.footer}>
          <div className={classes.buttonsWrap}>
            <SecondaryButton
              title={
                <span>
                  <PlusIcon /> Add attribute
                </span>
              }
              onClick={handleAddAttribute}
            />
            <div>
              <SubmitButton
                title={'Close'}
                type={'secondary'}
                styles={{ border: 'none' }}
                onClick={onClose}
              />
              <SubmitButton
                title={'Save'}
                styles={{ borderRadius: 4 }}
                onClick={!error && handleSaveAttributes}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

AttributesModal.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(AttributesModal)),
)
